/* HeroSection.css */

.hero_section {
    margin-top: 95px;
    position: relative;
    width: 100%;
  }
  .hero_section img{
    width: 100%;
  }
  .hero_section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5); /* White overlay with 50% opacity */
    z-index: 1;
  }
  .content_box{
   padding-top: 80px;
   padding-bottom: 80px;
  }
h1.promotion_tile{
    width: 100%;
    float: left;
    text-align: left;
    font-size: 70px;
    line-height: 100px;
    color: #CF652D;
    font-family:  var(--interBold-fonts);
    margin-bottom: 40px;
}
a.promotion-submit-btn{
    background: #CF652D;
    border: solid 2px #CF652D;
    color: #fff;
    border-radius: 50px;
    padding: 9px 30px;
    font-size: 24px;
    font-family:  var(--interrMedium-fonts);
    line-height: 36px;
    text-decoration: none;
}
.partner_link_section{
   width: 100%;
   float: left;
   margin-bottom: 15px;
}
.btn_top_margin{
    margin-top:40px;
}


/* SectionOne.css */


.section_one{
    margin-top: 70px;
    width: 100%;
    float: left;
    margin-bottom: 60px;
}
h2.njt_new_inner_heading {
    width: 100%;
    float: left;
    text-align: left;
    font-size: 40px;
    line-height: 60px;
    color: #AB3495;
    font-family:  var(--interrSemiBold-fonts);
    margin-bottom: 40px;
}
.njt_new_paraght{
    text-align: left;
    color: #545454;
    font-size: 24px;
    line-height: 36px;
    width: 100%;
    float: left;
    font-family:  var(--interrRegular-fonts);
    margin-top: 0px;
}
.image_box{
    display:flex;
    justify-content: flex-end;
}



/* SectionTwo.css */

.section_two{
    padding-top: 70px;
    width: 100%;
    float: left;
    padding-bottom: 60px;
    background: #F9FAFA;
}
.image_box_reach{
    display:flex;
    justify-content: flex-start;
}
h2.njt_new_reach_heading {
    width: 100%;
    float: left;
    text-align: left;
    font-size: 40px;
    line-height: 60px;
    color: #AB3495;
    font-family:  var(--interrSemiBold-fonts);
    margin-bottom: 0px;
}
.njt_new_bold_paraght{
    text-align: left;
    color: #545454;
    font-size: 24px;
    line-height: 36px;
    width: 100%;
    float: left;
    font-family:  var(--interrSemiBold-fonts);
    margin-bottom: 26px;
}
.njt_new_paraght.margin_new_p{
    margin-bottom: 26px;
}



/* SectionThree.css */

h2.njt_new_discover_heading{
    width: 100%;
    float: left;
    text-align: center;
    font-size: 40px;
    line-height: 60px;
    color: #CF652D;
    font-family:  var(--interrSemiBold-fonts);
    margin-bottom: 40px;
}
.partner_new_btn{
    display: flex;
    justify-content: center;
    width: 100%;
    float: left;
}
.section_two.last_section {
    margin-bottom: 100px;
}



@media screen and (max-width: 767px) {
    .hero_section{
        margin-top: 65px;
    }
    .content_box{
        padding-top: 40px;
        padding-bottom: 40px;
      }
      h1.promotion_tile{
        font-size: 24px;
        line-height: 36px;
        color: #CF652D;
        margin-bottom: 24px;
    }
    .hero_section img{
        height: 220px;
    }
    a.promotion-submit-btn{
        padding: 9px 20px;
        font-size: 16px;
        line-height: 24px;
    }
    .section_one{
        margin-top: 60px;
        width: 100%;
        float: left;
        margin-bottom: 50px;
    }
    h2.njt_new_inner_heading {
        text-align: center;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 20px;
    }
    .njt_new_paraght{
        text-align: center;
        font-size: 16px;
        line-height: 24px;
    }
    .image_box{
        justify-content: center;
    }
    .image_box img{
        width: 100%;
    }
    .image_box.mobile {
        display: block;
        margin-bottom: 24px;
    }
    .image_box.desk {
        display: none;
    }
    .section_two{
        padding-top: 60px;
        padding-bottom: 50px;
    }
    .image_box_reach img{
        width: 100%; 
    }
    .image_box_reach{
        margin-bottom: 24px;
    }
    h2.njt_new_reach_heading {
        text-align: center;
        font-size: 24px;
        line-height: 36px;
    }
    .njt_new_bold_paraght{
        text-align: center;
        color: #545454;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
    }
    .njt_new_paraght.margin_new_p {
        margin-bottom: 8px;
    }
    h2.njt_new_discover_heading{
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 20px;
    }
}





@media screen and (min-width: 768px) {
    .image_box.mobile {
        display: none;
    }
    .image_box.desk {
        display: block;
    }
}



@media only screen and (max-width: 1140px) and (min-width: 768px){
    .hero_section{
        margin-top: 65px;
    }
    .content_box{
        padding-top: 40px;
        padding-bottom: 40px;
      }
      h1.promotion_tile{
        font-size: 24px;
        line-height: 36px;
        color: #CF652D;
        margin-bottom: 24px;
    }
    .hero_section img{
        width: 100%;
    }
    a.promotion-submit-btn{
        padding: 9px 20px;
        font-size: 16px;
        line-height: 24px;
    }
    .section_one{
        margin-top: 60px;
        width: 100%;
        float: left;
        margin-bottom: 50px;
    }
    h2.njt_new_inner_heading {
        text-align: left;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 20px;
    }
    .njt_new_paraght{
        text-align: left;
        font-size: 16px;
        line-height: 24px;
    }
    .image_box img{
        width: 100%;
    }
    .section_two{
        padding-top: 60px;
        padding-bottom: 50px;
    }
    .image_box_reach img{
        width: 100%; 
    }
    .image_box_reach{
        margin-bottom: 24px;
    }
    h2.njt_new_reach_heading {
        text-align: left;
        font-size: 24px;
        line-height: 36px;
    }
    .njt_new_bold_paraght{
        text-align: left;
        color: #545454;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
    }
    .njt_new_paraght.margin_new_p {
        margin-bottom: 8px;
    }
    h2.njt_new_discover_heading{
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 20px;
    }
}