
.teaser-box {
    width: 100%;
    float: left;
}
.teaser-col-1 {
    width: 65%;
    float: left;
    padding-bottom: 68px;
    padding-top: 48px;
}
.teaser-col-2 {
    width: 25%;
    float: left;
}
img.teaser-log {
    width: 100%;
}
.teaser-form-field {
    width: 100%;
    float: left;
}
.teaser-form-box .li_container {
    flex-direction: column;
}
.teaser-form-box input.li_input {
    width: 100% !important;
}
.teaser-form-box .li_input_container {
    margin-left: 0px;
}
.col-12.col-sm-12.col-md-12.col-lg-9.col-xl-9.bg-color-res {
    background: #C566A8;
    /* min-height: 100vh; */
}
.col-12.col-sm-12.col-md-12.col-lg-3.col-xl-3.bg-color-res-2 {
    background: #FDE2CD;
    /* min-height: 100vh; */
}
.teaser-bbb {
    width: 100%;
    float: left;
}
.teaser-inn-b {
    width: 100%;
    float: left;
}
.teaser-col-box1 {
    width: 100%;
    float: left;
}
.first-heading {
    width: 450px;
    float: right;
    font-size: 120px;
    text-align: right;
    line-height: 120px;
    font-family: var(--interBold-fonts);
    letter-spacing: 2.4px;
    text-transform: uppercase;
    color: #000;
}
.heading-two {
    float: right;
    color: #FDE2CD;
    font-size: 100px;
    line-height: 100px;
    letter-spacing: 2.4px;
    text-align: right;
    font-family: var(--interBold-fonts);
    text-transform: uppercase;
    margin-bottom: 0px;
}
.teaser-form-box {
    width: 660px;
    float: right;
}
.teaser-inner-form-des {
    width: 100%;
    float: left;
    font-family:  var(--interrMedium-fonts);
    text-align: left;
    font-size: 24px;
    line-height: 36px;
    color: #fff;
    margin-bottom: 12px;
}
.teaser-inner-form-des.margin {
    margin-bottom: 32px;
}
.inner-new-design-bb {
    width: 100%;
    float: left;
    margin-top: 24px;
}

.teaser-inner-form-new {
    width: 100%;
    float: left;
    text-align: left;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    font-family:  var(--interrMedium-fonts);
}
.teaser-inner-form-new a {
    color: #0091FF;
    text-decoration: underline;
}
.headinf-teaser {
    width: 100%;
    float: left;
}
.tearr-box {
    width: 100%;
    float: left;
    margin-top: 24px;
}
.teaser-bbb label.li_label {
    color: #fff !important;
    font-size: 24px;
    line-height: 36px;
    font-family:  var(--interrMedium-fonts);
}
.btn-teaser {
    width: 100%;
    float: left;
    text-align: right;
    margin-top: 27px;
}
button.teaser-form-button {
    background: #004F99;
    border: solid 2px #004F99;
    color: #fff;
    font-size: 24px;
    line-height: 36px;
    font-family:  var(--interrMedium-fonts);
    padding: 9px 18px;
    border-radius: 9px;
}
.logo-teaser-box {
    position: absolute;
    bottom: 14px;
}
.tearr-box span {
    color: #fff !important;
}
.tearr-box p.li_error_text {
    color: #000 !important;
}


/**************************************** Mobile Responsive ****************************************/
@media only screen and (max-width: 320px) {
    .teaser-box {
        min-height: 100%;
    }
    .logo-teaser-box {
        position: unset !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 12px;
    }
    .first-heading {
        width: 150px;
        float: left;
        text-align: left;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0.8px;
    }
    .heading-two {
        text-align: left;
        float: left;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 1px;
    }
    .teaser-form-box {
        width: 100%;
        float: left;
    }
    .teaser-col-1 {
        width: 100%;
        padding-bottom: 40px;
        padding-top: 64px;
    }
    .teaser-inner-form-des {
        font-size: 16px;
        line-height: 24px;
    }
    .teaser-bbb label.li_label {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0px !important;
        padding-bottom: 8px !important;
    }
    .btn-teaser {
        margin-top: 12px;
    }
    button.teaser-form-button {
        font-size: 16px;
        line-height: 24px;
        padding: 6px 12px;
    }
    .teaser-col-2 {
        width: 100%;
    }
    img.teaser-log {
        width: 109px;
    }
    .headinf-teaser {
        padding-top: 6px;
    }
    .tearr-box {
        margin-top: 33px;
    }
    .mainbox-img {
        display: none;
    }
    .teaser-inner-form-new {
        font-size: 12px;
        line-height: 18px;
    }
}

@media only screen and (max-width: 560px) and (min-width: 321px) {
    .mainbox-img {
        display: none;
    }
    .teaser-box {
        min-height: 100%;    }
    .logo-teaser-box {
        position: unset !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 0px;
    }
    .first-heading {
        width: 177px;
        float: left;
        text-align: left;
        font-size: 46px;
        line-height: 46px;
        letter-spacing: 1px;
    }
    .heading-two {
        text-align: left;
        float: left;
        font-size: 60px;
        line-height: 60px;
        letter-spacing: 1px;
    }
    .teaser-form-box {
        width: 100%;
        float: left;
    }
    .teaser-col-1 {
        width: 100%;
        padding-bottom: 40px;
        padding-top: 64px;
    }
    .teaser-inner-form-des {
        font-size: 16px;
        line-height: 24px;
    }
    .teaser-bbb label.li_label {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0px !important;
        padding-bottom: 8px !important;
    }
    .btn-teaser {
        margin-top: 12px;
    }
    button.teaser-form-button {
        font-size: 16px;
        line-height: 24px;
        padding: 6px 12px;
    }
    .teaser-col-2 {
        width: 100%;
    }
    img.teaser-log {
        width: 109px;
    }
    .headinf-teaser {
        padding-top: 6px;
    }
    .tearr-box {
        margin-top: 33px;
    }
    .col-12.col-sm-12.col-md-12.col-lg-3.col-xl-3.bg-color-res-2 {
        background: #FDE2CD;
        /* min-height: 100vh; */
    }
    .teaser-inner-form-new {
        font-size: 12px;
        line-height: 18px;
    }
}

@media only screen and (max-width: 991px) and (min-width: 561px) {
    .col-12.col-sm-12.col-md-12.col-lg-3.col-xl-3.bg-color-res-2 {
        background: #FDE2CD;
        /* height: 30vh; */
    }
    .teaser-box {
        min-height: 100%;
       
    }
    .logo-teaser-box {
        position: unset !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 12px;
    }
    .first-heading {
        width: 185px;
        float: left;
        text-align: left;
        font-size: 60px;
        line-height: 60px;
        letter-spacing: 1px;
    }
    .heading-two {
        text-align: left;
        float: left;
        font-size: 64px;
        line-height: 64px;
        letter-spacing: 1px;
    }
    .teaser-form-box {
        width: 100%;
        float: left;
    }
    .teaser-col-1 {
        width: 100%;
        padding-bottom: 40px;
        padding-top: 64px;
    }
    .teaser-inner-form-des {
        font-size: 16px;
        line-height: 24px;
    }
    .teaser-inner-form-new {
        font-size: 12px;
        line-height: 18px;
    }
    .teaser-bbb label.li_label {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0px !important;
        padding-bottom: 8px !important;
    }
    .btn-teaser {
        margin-top: 12px;
    }
    button.teaser-form-button {
        font-size: 16px;
        line-height: 24px;
        padding: 6px 12px;
    }
    .teaser-col-2 {
        width: 100%;
    }
    img.teaser-log {
        width: 109px;
    }
    .headinf-teaser {
        padding-top: 6px;
    }
    .tearr-box {
        margin-top: 33px;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
    .heading-two {
        font-size: 70px;
        line-height: 70px;
    }
    .teaser-form-box {
        width: 98%;
    }
    .first-heading {
        font-size: 60px;
        line-height: 60px;
        width: 242px;
    }
}
@media only screen and (max-width: 1260px) and (min-width: 1200px) {
    .heading-two {
        font-size: 64px;
        line-height: 64px;
    }
    .teaser-form-box {
        width: 98%;
    }
    .first-heading {
        font-size: 60px;
        line-height: 60px;
        width: 300px;
    }
}
@media only screen and (max-width: 540px) {
    .pre-teaser-left-121.tabs-teaser {
        display: none !important;
    }
    .pre-teaser-right121.desktop-teaser {
        display: none !important;
    }
    .pre-teaser-right121.mobile-tease {
        display: block !important;
    }
    .pre-teaser-left-121.tabs-teaser-2{
        display: none !important;
    }
}
@media only screen and (max-width: 992px) and (min-width: 541px)  {
    .pre-teaser-left-121.tabs-teaser {
        display: none !important;
    }
    .pre-teaser-right121.desktop-teaser {
        display: none !important;
    }
    .pre-teaser-right121.mobile-tease {
        display: none !important;
    }
    .pre-teaser-left-121.tabs-teaser-2{
        display: block !important;
    }
    .pre-teaser-lg-212 {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
        position: absolute;
        right: 0px;
    }
    .pre-teaser-lg-212 img {
        width: 340px;
    }
}

@media only screen and (max-width: 1139px) and (min-width: 992px) {
    .pre-teaser-left-121.tabs-teaser {
        display: block !important;
    }
    .pre-teaser-right121.desktop-teaser {
        display: none !important;
    }
    .pre-teaser-right121.mobile-tease {
        display: none !important;
    }
    .pre-teaser-left-121.tabs-teaser-2{
        display: none !important;
    }
    /* .col-12.col-sm-12.col-md-12.col-lg-9.col-xl-9.bg-color-res{
        height: 100vh !important;
    }
    .col-12.col-sm-12.col-md-12.col-lg-3.col-xl-3.bg-color-res-2{
        height: 100vh !important;
    } */
    .pre-teaser-left-121.tabs-teaser img {
        width: 481px;
    }
}
@media only screen and (min-width: 1140px) {
    .pre-teaser-left-121.tabs-teaser {
        display: none !important;
    }
    .pre-teaser-right121.desktop-teaser {
        display: block !important;
    }
    .pre-teaser-right121.mobile-tease {
        display: none !important;
    }
    .pre-teaser-left-121.tabs-teaser-2{
        display: none !important;
    }
}


@media only screen and (max-width: 991px) and (min-width: 768px) {
    .col-12.col-sm-12.col-md-12.col-lg-3.col-xl-3.bg-color-res-2 {
        height: 20vh;
    }
}