.clddi_input_container{
  display: flex;
}

.clddi_input_container .clddi_label{
  padding: 10px;
  box-sizing: border-box;
  color: #666666;
  font-family: var(--interrMedium-fonts);
}
.clddi_label{
  font-size: 24px !important;
  line-height: 36px !important;
  margin-bottom: 0px !important;
  font-family: var(--interrRegular-fonts);
  padding: 10px 0px;
}
.clddi_input_container .clddi_div1{
  margin-left: 20px;
}
.clddi_input_container .clddi_div1 .clddi_container{
  position: relative;

  width: 300px;
  display: flex;
  align-items: center;
  box-sizing: border-box;

}
/* select.clddi_input {
  box-sizing: border-box;
  border: 1px solid #cccccc !important;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  outline: none;
  height: 48px;
  color: #666666;
  border-radius: 4px !important;
  vertical-align: middle;
  font-family: var(--interrMedium-fonts);
} */
.clddi_input {
  box-sizing: border-box;
  border: 1px solid #ab3495 !important;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  /* outline: none; */
  height: 48px;
  color: #666666;
  border-radius: 4px !important;
  vertical-align: middle;
  font-family: var(--interrMedium-fonts);
}
.clddi_input_container .clddi_div1 .clddi_container .clddi_input{
  position: relative;
  /* all: unset; */
  width: 100%;
  border:1px solid var(--primaryColor)!important;

  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
  cursor: pointer;
  color: #666666;
}

.clddi_input_container .clddi_div1 .clddi_container .clddi_icon{
position: absolute;
right: 0;
}

.clddi_input_container .clddi_error_text{
  margin-top: 5px;
  font-size: 12px;
  color: red;
  text-align: left;
}