.box-call-back {
    width: 100%;
    float: left;
}
.box-call-back label.li_label {
    text-align: left;
    color: #000 !important;
}
.box-call-back  label.clddi_label {
    text-align: left;
    color: #000 !important;
}
.box-call-back p.li_error_text {
    margin-bottom: 0px;
}
.success-msge-box {
    width: 100%;
    float: left;
    padding: 40px 0px;
}
.success-msge-box .thank-box {
    height: 100% !important;
}
.input-b p.ltai_label {
    display: none !important;
}