.perx-schdule {
    width: 100%;
    float: left;
    margin-top: 46px;
    margin-bottom: 40px;
}
.perx-schdule .li_container .li_input_container .li_input {
    border: solid 1px #6100C5 !important;
}
.perx-schdule .input-b textarea.ltai_input {
    border: solid 1px #6100C5 !important;
}
.perx-schdule button.help-submit-btn {
    background: #6100C5;
    border: solid 2px #6100C5;
}
.perx-schdule button.help-submit-btn:hover {
    background: #fff;
    color: #6100C5;
}