

p {
    margin: 0px !important;
}
::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}


.version-get-b {
  position: fixed;
  bottom: 6px;
  right: 0;
  width: 20px;
  text-align: center;
  box-shadow: 2px 3px 6px #000;
  min-height: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  background: #ffffff;
}
.version {
  color: #545454;
  font-size: 8px;
  font-family: var(--interBold-fonts)
}

.strapi-failure {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  color: black;
  padding: 0px 20px;
  /* font-size: 15px; */
}