.term-btn-inner-b {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 20px 0px;
    box-shadow: 8px 8px 17px #0000 !important;
    z-index: 99999;
}
.term-condtion-txt {
    width: 100%;
    float: left;
}
.term-txt-box33 {
    width: 100%;
    float: left;
    padding-bottom: 24px;
    color: var(--headingColor);
    font-family:  var(--interBold-fonts);
}
.term-btn-box {
    width: 100%;
    float: left;
    background: #fff;
}
.btn-term {
    width: 50%;
    float: left;
}
.term-img-f {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    text-align: center;
    
}
button.btn-acc {
    background: var(--primaryColor);
    border: 2px solid var(--primaryColor);
    color: #fff;
    border-radius: 5px;
    padding: 6px 28px;
    font-size: 16px;
    font-family:  var(--interrSemiBold-fonts);
    pointer-events: auto;
    cursor: pointer;
}
.term-inner-box {
    width: 100%;
    float: left;
    margin-top: 20px;
    /* padding: 20px; */
    background: #fff;
    text-align: left;
    max-height: 80vh; 
    overflow: auto;
    padding: 20px;
    border: solid 1px var(--primaryColor);
    margin-bottom: 60px;
}
/* .term-box {
    min-height: 82vh;
} */
.term-txt ol li {
    width: 100%;
    float: left;
    text-align: left;
    color: #000;
    font-size: 20px;
    line-height: 30px;
    font-family:  var(--interrRegular-fonts);
}
.term-txt-b {
    width: 100%;
    float: left;
}
strong{
    font-family:  var(--interBold-fonts);
}
.term-text{
    width: 100%;
    float: left;
    text-align: left;
}
.term-text p{
    font-size: 14px;
    color: var(--allTextColor);
    font-family:  var(--interrSemiBold-fonts);
    text-align: left;
}
.term-text.margin-top {
    padding-top: 30px;
}
.term-text.margin-1 {
    padding-top: 20px;
}
.term-text code {
    width: 100%;
    float: left;
    text-align: left;
}


/* div#section1 {
    bottom: 0px;
    overflow: auto;
} */
/* .term-box {
    scroll-behavior: smooth;
  }
  
  #section1 {
    height: 100vh;
  } */
  
  /* #section2 {
    height: 200px;
  } */
  .btn-acc-active{
    background: #ccc;
    border: 0px;
    color:#fff;
    border-radius: 5px;
    padding: 8px 30px;
    font-size: 16px;
    font-family:  var(--interrSemiBold-fonts);
    pointer-events: none;
  }
  .term-img-f img {
    width: auto !important;
    height: 262px;
    
}
.web-view {
    width: 360px;
    margin: 0px auto;
}
div#section1 {
    background: #fff;
    width: 100%;
    float: left;
}
.enable-scroll{
    overflow-y: scroll;
    max-height: 400px;
    width: 100%;
    float: left;
    padding: 20px;
    background: #fff;
    text-align: left;
    margin-top: 262px;
}
.disable-scroll {
    overflow-y: unset;
    max-height: 399px;
    width: 100%;
    float: left;
    padding: 20px;
    background: #fff;
    text-align: left;
    margin-top: 262px;
}
button.btn-acc1 {
    background: var(--primaryColor);
    border: 2px solid var(--primaryColor);
    color: #fff;
    border-radius: 5px;
    padding: 6px 28px;
    font-size: 16px;
    font-family:  var(--interrSemiBold-fonts);
    pointer-events: auto;
    cursor: pointer;
}
.btn-acc-active1{
    background: rgba(0, 209, 129, 0.6);
    border: 0px ;
    color:#fff;
    border-radius: 5px;
    padding: 8px 30px;
    font-size: 16px;
    font-family:  var(--interrSemiBold-fonts);
    pointer-events: none;
  }
.help-text.term-text p{
    font-size: 14px !important;
    font-family:  var(--interrSemiBold-fonts);
    width: 100%;
    float: left;
    color: var(--portalText);
    margin-bottom: 30px !important;
}
.help-model-term {
    width: 100%;
    float: left;
}
.del-pop-bnt.term-button {
    margin: 0px 30px;
}
.del-pop-bnt.term-box-ok {
    text-align: center;
    width: 100%;
    float: left;
    /* margin-top: 20px; */
}
button.term-cond-btn {
    background: var(--primaryColor);
    color: #fff;
    border: solid 2px var(--primaryColor);
    padding: 10px 38px;
    border-radius: 8px;
    font-size: 14px;
    font-family:  var(--interrSemiBold-fonts);
}
h1.term-and-head {
    width: 100%;
    float: left;
    text-align: center;
    color: var(--headingColor);
    font-size: 40px;
    line-height: 60px;
    font-family:  var(--interrMedium-fonts);
    padding: 50px 0px;
}

footer.t-footer-wrap {
    width: 100%;
    float: left;
    padding-top: 24px;
    padding-bottom: 28px;
  }

.term-txt p {
   padding-bottom: 28px;
    width: 100%;
    float: left;
    text-align: left;
    color: #000;
    font-size: 20px;
    line-height: 30px;
    font-family:  var(--interrRegular-fonts);
}
.term-txt p img {
    width: 100% !important;
}
.term-txt h1{
    color: #000000 !important;
    font-family:  var(--interBold-fonts);
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}
.margin-bottom {
    margin-bottom: 0px;
}
.term-txt h2{
    color: #000000 !important;
    font-family:  var(--interBold-fonts);
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}
.term-txt h3{
    color: #000000 !important;
    font-family:  var(--interBold-fonts);
    font-size: 20px;
    line-height: 30px;
    text-align: left;
}
.term-txt h4{
    color: #000000 !important;
    font-family:  var(--interBold-fonts);
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    margin-bottom: 28px;
}
.term-txt h5{
    color: #000000 !important;
    font-family:  var(--interBold-fonts);
    font-size: 20px;
    line-height: 30px;
    text-align: left;
}
.term-txt h6{
    color: #000000 !important;
    font-family:  var(--interBold-fonts);
    font-size: 20px;
    line-height: 30px;
    text-align: left;
}
.term-box-header {
    width: 100%;
    float: left;
}
footer.t-footer-wrap.footer-f-tc {
    bottom: 0px;
    position: absolute;
}

.term-slider-box {
    width: 100%;
    float: left;
    margin-bottom: 20px;
}
.term-head-boxx {
    width: 100%;
    float: left;
    margin-top: 154px;
}
h1.term-headig {
    margin-bottom: 0px;
    color: #AB3495;
    font-family:  var(--interBold-fonts);
    font-size: 20px;
    text-align: center;
    line-height: 30px;
}
.main-slider-term img {
    width: 100%;
    border: solid 1px var(--primaryColor);
    border-radius: 30px;
}
.main-slider-term {
    width: 100%;
    float: left;
    margin-top: 40px;
    min-height: 400px;
}
.term-dd {
    width: 100%;
    float: left;
    background: #fff;
}
.inner-term-para {
    width: 100%;
    float: left;
    padding: 20px;
    border: solid 1px var(--primaryColor);
    margin-top: 40px;
}

.term-txt ol {
    text-align: left;
    width: 100%;
    float: left;
}
.term-txt ul {
    width: 100%;
    float: left;
    text-align: left;
    /* padding-top: 14px; */
    padding-bottom: 28px;
    margin-bottom: 0px;
}
.term-txt ul li {
    width: 100%;
    float: left;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    color: #000;
}
.term-txt b {
    font-family:  var(--interBold-fonts);
}
h1.margin-last {
    margin-bottom: 28px;
}

.term-txt table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .term-txt td{
    border: 1px solid #000;
    text-align: left;
    padding: 8px;
    color: #000;
  }
  .term-txt th {
    border: 1px solid #000;
    text-align: left;
    padding: 8px;
    color: #000;
  }

  table.terms-table tr th {
    width: 33% !important;
    text-align: center;
    font-family:  var(--interBold-fonts);
    font-size: 20px;
    line-height: 30px;
}
table.terms-table tr td {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    font-family:  var(--interrRegular-fonts);
}
table.terms-table{
    width: 100%;
    float: left;
    margin-bottom: 28px;
}

/************************ mobile queery ***********************/
@media screen and (max-width: 320px) {
    .web-view {
        width: 100% !important;
    }
    .term-txt-box33 {
        font-size: 12px;
    }
}

@media screen and (max-width: 768px) {
    table.terms-table tr th {
        font-size: 16px;
        line-height: 24px;
    }
    table.terms-table tr td{
        font-size: 16px;
        line-height: 24px;
    }
    .main-slider-term {
        min-height: 200px;
    }
    .term-txt h1{
        font-size: 16px;
        line-height: 24px;
    }
    .term-txt h2{
        font-size: 16px;
        line-height: 24px;
    }
    .term-txt h3{
        font-size: 16px;
        line-height: 24px;
    }
    .term-txt h4{
        font-size: 16px;
        line-height: 24px;
    }
    .term-txt h5{
        font-size: 16px;
        line-height: 24px;
    }
    .term-txt h6{
        font-size: 16px;
        line-height: 24px;
    }
    .term-txt p {
        font-size: 16px;
        line-height: 24px;
    }
    .term-txt ol li {
        font-size: 16px;
        line-height: 24px;
    }
    .term-txt ul li {
        font-size: 16px;
        line-height: 24px;
    }
    .main-slider-term img {
        border-radius: 12px;
    }
  }