svg {
    width: 100px;
    display: block;
    margin: 40px auto 0;
  }
  
  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
  }
    .circle {
      -webkit-animation: dash .9s ease-in-out;
      animation: dash .9s ease-in-out;
    }
    .line {
      stroke-dashoffset: 1000;
      -webkit-animation: dash .9s .35s ease-in-out forwards;
      animation: dash .9s .35s ease-in-out forwards;
    }
    .check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check .9s .35s ease-in-out forwards;
      animation: dash-check .9s .35s ease-in-out forwards;
    }
  
  
  p {
    text-align: center;
    margin: 20px 0 60px;
    font-size: 1.25em;
  }
    .success {
      color: #73AF55;
    }
    .error {
      color: #D06079;
    }
  
  
  
  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
  
  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
  

  .thanks-wrap {
    width: 100%;
    float: left;
}
.thank-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    align-content: center;
    height: 120vh;
}
.thank-box .contact-box {
  height: 90vh !important;
}
.thank_box_new{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
}
.thank-txt-box {
    display: flex;
    flex-direction: column;
}
.thank-txt-box-new{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
h2.thanks-title {
  font-family:  var(--interrMedium-fonts);
    font-size: 40px;
    line-height: 60px;
    margin-top: 30px;
    color: #AB3495;
}
.thaks-txt {
    font-size: 24px;
    line-height: 36px;
    color: #545454;
    font-family:  var(--interrRegular-fonts);
    margin-bottom: 60px;
}
.thaks-txt-signup{
  font-size: 24px;
  line-height: 36px;
  color: #545454;
  font-family:  var(--interrRegular-fonts);
  margin-bottom: 30px;
}
.button-thnks a:hover {
    background: transparent !important;
    border: solid 2px var(--primaryColor) !important;
    color: var(--primaryColor) !important;
}
.button-thnks a {
background: var(--primaryColor);
  border: solid 2px var(--primaryColor);
  color: #fff;
  border-radius: 8px;
  padding: 7px 15px;
  font-size: 24px;
  line-height: 36px;
  font-family:  var(--interrMedium-fonts);
}
.modal_signup {
  display: flex;
  width: 100%;
  justify-content: center;
}

.thank_box_new svg {
  width: 70px !important;
}

/********************* Mobile Responsive *****************************/
@media only screen and (max-width: 600px){
  .responsive_modal {
    width: 90% !important;
}
h2.thanks-title {
  width: 100%;
  float: left;
  font-size: 20px;
  line-height: 30px;
  font-family:  var(--interBold-fonts);
}
.thaks-txt {
  font-size: 16px;
  line-height: 24px;
}
.thaks-txt-signup{
  font-size: 16px;
  line-height: 24px;
}
.thank-txt-box {
    padding: 0px 20px;
}

.button-thnks a {
  font-size: 16px;
  line-height: 24px;
  padding: 4px 10px;
}
}