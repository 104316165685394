.perx-footer {
    width: 100%;
    float: left;
    background: #F9FAFA;
    padding-top: 24px;
    padding-bottom: 26px;
}
.link-perx-footer {
    width: 100%;
    float: left;
}
a.perx-footer-link {
    color: #545454;
    font-size: 24px;
    line-height: 36px;
    font-family:  var(--interrRegular-fonts);
}
.perx-logo-copy {
    width: 100%;
    float: left;
    text-align: left;
    font-size: 24px;
    line-height: 36px;
    color: #545454;
}