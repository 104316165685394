.njtheader a.mb-0.img-box img {
    width: 100%;
    height: unset;
}
header.njtheader {
    background: #DFE6E9;
    padding: 10px 0px;
    min-height: 124px;
}
header.njtheader ul.transit-links li.signup a.nav-link{
    background: #AB3495 !important;
}
nav ul li.njt-log a.nav-link {
    background: #AB3495;
    border-radius: 10px;
    color: #fff !important;
}
.site-navbar .site-navigation .site-menu > li.njt-log > a {
    color: #fff !important;
}
header#header ul.transit-links li.njt-sign a {
    border: solid 2px #AB3495 !important;
    border-radius: 10px;
    color: #AB3495 !important;
}
h2.njt-heading1 {
    font-size: 60px;
    line-height: 72px;
    color: #AB3495;
    font-family:  var(--interBold-fonts);
}
h1.njt-heading1 {
    font-size: 60px;
    line-height: 72px;
    color: #AB3495;
    font-family:  var(--interBold-fonts);
}
.njt-text-app {
    font-size: 36px;
    color: #545454;
    line-height: 54px;
    font-family:  var(--interrRegular-fonts) !important;
    margin-top: 30px;
}
.njt-section-2 {
    width: 100%;
    float: left;
    background: url("../../Assets/Images/Background1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 120px;
    padding-top: 80px;
}
h2.njt-heading-2 {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 60px;
    line-height: 90px;
    color: #AB3495;
    font-family:  var(--interrSemiBold-fonts);
    margin-bottom: 30px;
}
.njt-why-sec {
    width: 100%;
    float: left;
}

.njt-why-box {
    width: 100%;
    float: left;
}
.njt-why-des {
    width: 100%;
    float: left;
}
.njt-why-des ul li {
    text-align: left;
    color: #545454;
    font-size: 36px;
    line-height: 54px;
    width: 100%;
    float: left;
    margin-bottom: 23px;
    font-family:  var(--interrRegular-fonts);
}
.img-njt-3 {
    width: 100%;
    float: left;
    text-align: center;
}
.img-njt-3 img {
    width: 100%;
}
.njt-why-des ul {
    width: 100%;
    float: left;
}
#busines {
    width: 100%;
    float: left;
}
h2.njt-heading-3 {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 60px;
    line-height: 90px;
    color: #AB3495;
    font-family:  var(--interrSemiBold-fonts);
    margin-bottom: 40px;
}
.njt-section-3 {
    width: 100%;
    float: left;
    padding-top: 64px;
}
.njt-today-box ul li a {
    color: #0091FF;
    text-decoration: underline !important;
}
.njt-today-box {
    width: 100%;
    float: left;
}
.njt-today-box ul li {
    text-align: left;
    color: #545454;
    font-size: 36px;
    line-height: 54px;
    width: 100%;
    float: left;
    margin-bottom: 23px;
    font-family:  var(--interrRegular-fonts);
}
.njt-tody-paraght {
    text-align: left;
    color: #545454;
    font-size: 36px;
    line-height: 54px;
    width: 100%;
    float: left;
    font-family:  var(--interrRegular-fonts);
    margin-top: 16px;
}
.njt-today-box ul {
    width: 100%;
    float: left;
    padding-left: 20px;
    margin-bottom: 0px;
}
.njt-section-4 {
    width: 100%;
    float: left;
    padding-bottom: 60px;
    padding-top: 70px;
}
.njt-banner-img-box {
    width: 100%;
    float: left;
}
.njt-banner-img-box img {
    width: 100%;
}
.site-navbar.shrink ul.site-menu.main-menu.js-clone-nav.mr-auto.d-none.d-lg-block li.njt-log a {
    color: #fff !important;
}
.njt-sign-body {
    width: 100%;
    float: left;
    margin-top: 154px;
}
.njt-sign-body.become-partner{
    margin-top: 60px !important;
  }
h2.njt-sign-heading {
    float: left;
    width: 100%;
    text-align: center;
    color: #AB3495;
    font-size: 40px;
    line-height: 60px;
    font-family:  var(--interrMedium-fonts);
}
h1.njt-sign-heading {
    float: left;
    width: 100%;
    text-align: center;
    color: #AB3495;
    font-size: 40px;
    line-height: 60px;
    font-family:  var(--interrMedium-fonts);
}
.njt-sign-body .get-box {
    padding-top: 30px;
}
.njt-conctact-body {
    width: 100%;
    float: left;
    margin-top: 154px;
}
.njt-contact-wrap  textarea.ltai_input {
    border: 1px solid var(--primaryColor) !important;
}
.contact-in-faq textarea.ltai_input{
    border: 1px solid var(--primaryColor) !important;
}
.njt-not-txt {
    width: 100%;
    float: left;
}
.njt-not-txt p{
    font-size: 20px;
    color: #545454;
    line-height: 30px;
    font-family:  var(--interrRegular-fonts);
    text-align: left;
    margin-top: 30px !important;
}
.njt-note-box {
    width: 100%;
    float: left;
    margin-top: 0px;
    margin-bottom: 85PX;
}
.njt-not-txt a {
    color: #0091FF;
    text-decoration: underline;
}
.njt-faq-body {
    width: 100%;
    float: left;
    margin-top: 154px;
}
.contact-in-faq {
    width: 100%;
    float: left;
    margin-top: 64px;
}
.njt-contact-wrap .input-b label.ltai_label {
    width: 100%;
    float: left;
    margin-bottom: 0px;
    padding-left: 0px;
    color: var(--labelField);
    font-size: 24px;
    font-family:  var(--interrRegular-fonts);
    line-height: 36px;
    font-weight: unset !important;
    margin-top: 0px !important;
}
.njt-faq-body .input-b label.ltai_label{
    width: 100%;
    float: left;
    margin-bottom: 0px;
    padding-left: 0px;
    color: var(--labelField);
    font-size: 24px;
    font-family:  var(--interrRegular-fonts) !important;
    line-height: 36px;
    font-weight: unset !important;
    margin-top: 0px !important;
}
.input-b.last label.li_label {
   display: none;
}
.inner-label {
    width: 100%;
    float: left;
    margin-top: 6px;
    font-size: 16px;
    line-height: 24px;
    color: #545454;
}
.img-njt-4 {
    width: 100%;
    float: left;
    text-align: center;
}
.img-njt-4 img{
    width: 100%;
}
.deskstop .mob-res {
    text-align: right;
}

.col-sm-12.mobile .mob-res {
    text-align: center;
    margin-bottom: 30px;
}
.new-sign-page-design {
    width: 100%;
    float: left;
    margin-top: 40px;
    /* margin-bottom: 72px; */
}
.new-sign-btn-box {
    width: 100%;
    float: left;
    text-align: center;
}
.new-sign-btn-box a {
    font-family:  var(--interrMedium-fonts);
    font-size: 40px;
    line-height: 60px;
    padding: 13px 27px;
    background: #BA2099;
    border: solid 2px #BA2099;
    border-radius: 8px;
    color: #fff;
}
.box-new-term {
    width: 100%;
    float: left;
}
.new-term-of-use-tag {
    width: 100%;
    float: left;
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    color: #545454;
}
.new-term-of-use-tag a {
    color: #0091FF;
    text-decoration: underline;
}
.premium-inner-wrap {
    width: 100%;
    float: left;
    margin-top: 130px;
}
#njt-three{
    width: 100%;
    float: left;
}



/********************************** Mobile Responsive ***********************************/
@media only screen and (max-width: 991px) {

    .njt-logo-header {
        width: 100%;
        text-align: center;
    }
    button.navbar-toggler {
        position: absolute;
        top: 50px;
        z-index: 99999;
    }
    nav.navbar.navbar-expand-lg.fixed-top.navbar-light ul.navbar-nav li.nav-item a.nav-link.border {
        border: 0px !important;
    }
    nav.navbar.navbar-expand-lg.fixed-top.navbar-light ul.navbar-nav li a.nav-link.bg-color {
        background: transparent !important;
        border: 0px;
        color: rgba(0,0,0,.5) !important;
    }
    nav.navbar.navbar-expand-lg.fixed-top.navbar-light nav.site-navigation.position-relative.nav-menu {
        border-top: solid 2px #4A4A4A;
        margin-top: 20px;
    }
    nav.navbar.navbar-expand-lg.fixed-top.navbar-light li.nav-item {
        border-bottom: solid 2px #4A4A4A;
        text-align: left;
        padding-left: 0px !important;
    }
    nav.navbar.navbar-expand-lg.fixed-top.navbar-light li.nav-item a {
        text-align: left;
    }


    .col-6.col-lg-3.responsive-desk {
        display: none;
    }
    .responsive-mobile {
        display: block;
        width: 100%;
        justify-content: center;
    }
    .box-resp-menu {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .box-resp-menu .d-inline-block.d-lg-none.ml-md-0.py-3 {
        position: absolute;
        left: 20px;
        margin-top: 14px;
    }
    .burger:before {
        top: 10px;
    }
    .njt-site-mobile-menu-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 18px 0px;
        border-bottom: 2px solid #545454;
    }
    .njt-site-mobile-menu-close {
        position: absolute;
        left: 10px;
        margin-top: 16px;
    }
    .log-resp {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .site-mobile-menu.site-navbar-target {
        margin-top: 0px !important;
        width: 100%;
        background: #DFE6E9 !important;
        padding-top: 0px;
        z-index: 999999;
    }
    span.icofont-close.js-menu-toggle.active {
        font-size: 40px;
        color: #545454;
    }
    .log-resp img {
        width: 36px;
    }
    .site-mobile-menu-body ul.site-nav-wrap li a {
        font-size: 20px;
        line-height: 24px;
        border-bottom: solid 1px #545454;
        padding-left: 0px;
        font-family:  var(--interrMedium-fonts);
    }
    .site-mobile-menu-body {
        margin-top: 20px;
    }
    .njtheader a.mb-0.img-box img {
        width: 36px;
    }
    span.icofont-close.js-menu-toggle {
        font-size: 40px;
        color: #545454;
    }
    header.njtheader {
        min-height: 104px;
    }
  
}
@media only screen and (max-width: 767px) {
    button.help-submit-btn {
        font-size: 16px;
        line-height: 24px;
        padding: 8px 14px;
        border-radius: 6px;
    }
    button.navbar-toggler {
        top: 35px !important;
    }
    .njt-logo-header img {
        height: 50px !important;
    }
    .faq-new-design div.faqs-accord  {
        padding-top: 16px !important;
    }
    .njt-section-4 {
        padding-bottom: 40px;
        padding-top: 18px;
    }
    .njt-footer-wrap {
        padding-top: 10px !important;
        padding-bottom: 24px !important;
    }
   
    .responsive-mobile {
        width: 100%;
        justify-content: center;
        display: flex;
    }
    .burger:before {
        top: 10px;
    }
    .njt-site-mobile-menu-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 18px 0px;
        border-bottom: 2px solid #545454;
    }
    .njt-site-mobile-menu-close {
        position: absolute;
        left: 10px;
        margin-top: 16px;
    }
    .log-resp {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .site-mobile-menu.site-navbar-target {
        margin-top: 0px !important;
        width: 100%;
        background: #DFE6E9 !important;
        padding-top: 0px;
        z-index: 999999;
    }
    span.icofont-close.js-menu-toggle.active {
        font-size: 40px;
        color: #545454;
    }
    .log-resp img {
        width: 36px;
    }
    .site-mobile-menu-body ul.site-nav-wrap li a {
        font-size: 20px;
        line-height: 24px;
        border-bottom: solid 1px #545454;
        padding-left: 0px;
        font-family:  var(--interrMedium-fonts);
    }
    .site-mobile-menu-body {
        margin-top: 20px;
    }
    .njtheader a.mb-0.img-box img {
        width: 36px;
    }
    h2.njt-heading1 {
        font-size: 32px;
        line-height: 40px;
    }
    h1.njt-heading1 {
        font-size: 32px;
        line-height: 40px;
    }
    .njt-text-app {
        font-size: 20px;
        line-height: 30px;
    }
    .col-sm-12.col-md-5.resp-desk {
        display: block !important;
    }
    .col-sm-12.resp-mobile {
        display: none !important;
    }
    .hero-section.transit-section-1 {
        padding-bottom: 0px;
        padding-top: 120px;
    }
    .img-njt-3 {
        margin-bottom: 40px;
    }
    h2.njt-heading-2 {
        font-size: 32px;
        line-height: 40px;
        font-family:  var(--interBold-fonts);
    }
    
    .njt-why-des ul li {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
    }
    h2.njt-heading-3 {
        font-size: 32px;
        line-height: 40px;
        font-family:  var(--interBold-fonts);
        margin-bottom: 12px;
    }
    .njt-section-2 {
        padding-bottom: 68px;
        padding-top: 16px;
    }
    .njt-today-box ul li {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 8px;
    }
    .njt-tody-paraght {
        font-size: 20px;
        line-height: 30px;
        margin-top: 0px;
    }
    .njt-section-3 {
        padding-top: 32px;
    }
    .njt-footer-log img.njt-img-4 {
        height: 50px;
    }
    .njt-footer-link ul {
        padding-left: 0px;
    }
    .njt-footer-link ul li a {
        font-size: 12px;
        line-height: 18px;
        color: #545454 !important;
    }
    .njt-footer-link ul li {
        margin-bottom: 12px !important;
        line-height: 0px !important;
    }
    .njt-footer-copy {
        font-size: 12px !important;
        line-height: 18px !important;
    }
    .footer-use-njt a {
        font-size: 12px !important;
        line-height: 18px !important;
    }
    .njt-footer-link {
        margin-top: 20px;
    }
    .njt-sign-body {
        margin-top: 130px;
    }
    h2.njt-sign-heading {
        font-size: 20px;
        line-height: 30px;
        font-family:  var(--interBold-fonts);
    }
    h1.njt-sign-heading {
        font-size: 20px;
        line-height: 30px;
        font-family:  var(--interBold-fonts);
    }
    .njt-conctact-body {
        margin-top: 130px;
    }
    .njt-faq-body {
        margin-top: 130px;
    }
    .contact-in-faq {
        margin-top: 30px;
    }
    .njt-not-txt p {
        font-size: 12px;
        line-height: 16px;
    }
    button.btn.btn-link.collapsed {
        font-size: 16px !important;
        line-height: 24px !important;
    }
    div#accordionFlushExample h2.accordion-header button.accordion-button {
        font-size: 16px !important;
        line-height: 24px !important;
    }
    div#accordionFlushExample .accordion-body {
        font-size: 12px !important;
        line-height: 16px !important;
    }
    .img-njt-4.deskstop {
        display: none !important;
    }
    .img-njt-4.mobile {
        display: block !important;
    }
    .col-sm-12.col-md-4.deskstop {
        display: none !important;
    }
    .col-sm-12.mobile {
        display: block !important;
    }
    .new-sign-btn-box a {
        font-size: 20px;
        line-height: 30px;
        padding: 5px 13px;
       
    }
    .new-sign-page-design {
        margin-top: 20px;
        margin-bottom: 24px;
    }
    .new-term-of-use-tag {
        font-size: 12px;
        line-height: 18px;
    }
    .li_container .li_input_container .li_input{
        font-size: 12px !important;
        line-height: 18px !important;
    }
    .clddi_input_container .clddi_div1 .clddi_container .clddi_input{
        font-size: 12px !important;
        line-height: 18px !important;
    }
  }


/********************************** Deskstop Responsive ***********************************/
@media only screen and (min-width: 768px) {
    .deskstop {
        display: block !important;
    }
    .mobile {
        display: none !important;
    }
    .col-sm-12.col-md-4.deskstop {
        display: block !important;
    }
    .col-sm-12.mobile {
        display: none !important;
    }
}
  @media only screen and (min-width: 992px) {
    .responsive-mobile {
        display: none;
    }
    .col-6.col-lg-3.responsive-desk {
        display: block;
    }
  }