.perx-header-wrap {
    float: left;
    width: 100%;
    background: #DFE6E9;
}
.perx-logo {
    width: 100%;
    float: left;
    padding: 20px 0px;
}
.perx-logo img {
    width: 84px;
}
.perx-link-schdule {
    width: 100%;
    float: left;
    text-align: right;
}
.perx-logo a:hover {
    text-decoration: none;
}
a.perx-link-nav {
    border: solid 2px #5714BD;
    background: #5714BD;
    color: #fff;
    padding: 10px 14px;
    border-radius: 9px;
    font-size: 24px;
    line-height: 36px;
    font-family:  var(--interrMedium-fonts);
}
a.perx-link-nav:hover {
    background: #fff;
    color: #5714BD;
    text-decoration: none;
}
span.perx-logo-txt {
    font-size: 24px;
    line-height: 36px;
    color: #5714BD;
    font-family:  var(--interBold-fonts);
    padding-left: 30px;
}