.njt-footer-wrap {
    width: 100%;
    float: left;
    padding-top: 32px;
    padding-bottom: 55px;
    background: #F9FAFA;
}
.njt-footer-log {
    width: 100%;
    float: left;
}
.njt-footer-log img {
    height: 80px;
}
.njt-footer-link {
    width: 100%;
    float: left;
}
.njt-footer-link ul {
    width: 100%;
    float: left;
}
.njt-footer-link ul li {
    text-align: left;
    color: #545454;
    font-size: 24px;
    line-height: 36px;
    width: 100%;
    float: left;
    margin-bottom: 23px;
    font-family:  var(--interrRegular-fonts);
    list-style: none;
}
.njt-footer-link ul li a{ 
    color: #545454;
    text-decoration: none;
}
.njt-footer-link ul li a:hover{ 
    text-decoration: underline;
}
.njt-cop-last {
    width: 100%;
    float: left;
    border-top: solid 1px #D8D8D8;
    padding-top: 24px;
}
.njt-footer-copy {
    text-align: left;
    color: #545454;
    font-size: 24px;
    line-height: 36px;
    width: 100%;
    float: left;
    font-family:  var(--interrRegular-fonts);
}
.footer-use-njt a {
    text-align: left;
    color: #545454 !important;
    font-size: 24px;
    line-height: 36px;
    width: 100%;
    float: left;
    font-family:  var(--interrRegular-fonts);
    text-decoration: none;
}
.footer-use-njt a:hover{
    text-decoration: underline;
}



/********************************** Deskstop Responsive ***********************************/
@media only screen and (max-width: 767px) {
    .njt-footer-copy.desktop {
        display: none !important;
    }
    .njt-footer-copy.mobile {
        display: block !important;
    }
    img.njt-img-3.des {
        display: none !important;
    }
    img.njt-img-3.mobile {
        display: block !important;
    }
}

/********************************** Deskstop Responsive ***********************************/
@media only screen and (min-width: 768px) {
  .njt-footer-copy.desktop {
        display: block !important;
    }
    .njt-footer-copy.mobile {
        display: none !important;
    }
    img.njt-img-3.des {
        display: block !important;
    }
    img.njt-img-3.mobile {
        display: none !important;
    }
}