.loading-b {
    text-align: center;
}
.spinner-border.loading-color {
    color: var(--primaryColor);
}
.spinner-border {
    display: inline-block;
    color: var(--primaryColor);
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

  @keyframes spinner-border {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }