.clddi_input_container {
  display: flex;
}

.clddi_input_container .clddi_label {
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
  color: #666666;
  margin: 0px !important;
}
.clddi_input_container .clddi_div1 {
  margin-left: 20px;
}
.clddi_input_container .clddi_div1 .clddi_container {
  position: relative;
  /* margin-top: 16px; */
  /* background-color: chartreuse; */
  width: 300px;
  display: flex;
  align-items: center;
  /* padding: 10px; */
  box-sizing: border-box;
  /* border:1px solid black; */
  /* border-radius: 5px; */
}
select.clddi_input {
  box-sizing: border-box;
  border: 1px solid var(--primaryColor) !important;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  outline: none;
  height: 48px;
  color: #666666;
  border-radius: 4px !important;
  vertical-align: middle;

  font-family: 'Poppins', sans-serif;
}


.clddi_input_container .clddi_div1 .clddi_container .clddi_input {
  position: relative;
  /* all: unset; */
  width: 100%;
  border: 1px solid var(--primaryColor) !important;
  /* border-radius: 5px; */
  /* background-color: chartreuse; */
  padding: 10px;
  font-size: 20px;
  line-height: 30px;
  /* font-weight: 500; */
  z-index: 1;
  cursor: pointer;
  color: #646464;
  font-family:  var(--interrRegular-fonts);
}

.clddi_input_container .clddi_div1 .clddi_container .clddi_icon {
  position: absolute;
  right: 0;
  /* background-color: crimson; */
}

.clddi_input_container .clddi_error_text {
  margin-top: 5px;
  font-size: 12px;
  color: red;
  text-align: left;
}
