.main-box-404 {
    width: 100%;
    float: left;
}
.wrap-404 {
    width: 100%;
    float: left;
    margin-bottom: 24px;
    margin-top: 140px;
}
.errordiv {
    width: 100%;
    float: left;
}

h1.four {
    width: 100%;
    float: left;
    text-align: left;
    font-size: 60px;
    line-height: 72px;
    font-family:  var(--interBold-fonts);
    color: #AB3495;
}
.main-404 {
    width: 100%;
    float: left;
    text-align: left;
    font-size: 36px;
    left: 54px;
    font-family:  var(--interrRegular-fonts);
    color: #545454;
}
.back-link-404 {
    width: 100%;
    float: left;
    margin-top: 28px;
    text-align: left;

}
.back-link-404 a {
    padding: 8px 16px;
    background: #BA2099;
    color: #fff;
    font-size: 36px;
    line-height: 54px;
    border: solid 2px #BA2099;
    border-radius: 9px;
    font-family:  var(--interrMedium-fonts);
}
.box-img-404 {
    width: 100%;
    float: left;
    min-height: 460px;
}
.box-img-404 img {
    width: 100%;
}
.back-link-404 a:hover {
    text-decoration: none !important;
}


/*************************** Mobile Responsive ***************************/


@media only screen and (max-width: 767px) {
    .box-img-404.mobile-err {
        display: block;
        min-height: 320px;
        margin-bottom: 16px;
    }
    .box-img-404.deskt-err {
        display: none;
    }
    h1.four {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
    }
    .main-404 {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }
    .back-link-404{
        text-align: center;
    }
    .back-link-404 a {
        font-size: 16px;
        line-height: 24px;
        padding: 6px 10px;
    }
    .wrap-404 {
        width: 100%;
        float: left;
        margin-bottom: 24px;
        margin-top: 122px;
    }
  }

@media only screen and (min-width: 768px) {
    .box-img-404.mobile-err {
        display: none;
    }
    .box-img-404.deskt-err {
        display: block;
    }
}