.slider-2-wrap {
    width: 100%;
    float: left;
    background: url("../../Assets/Images/Background1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 120px;
    padding-top: 80px;
}
.crousel-box-4 {
    width: 100%;
    float: left;
}
.slider-txt-box {
    width: 100%;
    float: left;
}
.setup-1 {
    width: 100%;
    float: left;
    font-size: 20px;
    line-height: 30px;
    font-family: var(--interBold-fonts);
    color: #000;
    padding-top: 20px;
}
.slider-heading {
    width: 100%;
    float: left;
    font-size: 32px;
    line-height: 48px;
    color: var(--headingColor) ;
    padding: 16px 0px;
    font-family:  var(--interrMedium-fonts);
}
.slider-descrip {
    font-size: 24px;
    line-height: 36px;
    color: #000;
    font-family:  var(--interrRegular-fonts);
}
.slider-img {
    width: 100%;
    float: left;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.slider-img img {
    width: 90%;
}
.carousel-indicators {
    margin-left: 25% !important;
    margin-bottom: -15px !important;
}
.carousel-indicators li {
    height: 14px !important;
    width: 14px !important;
    background: #ffffff;
    border: 2px solid #000 !important;
    border-radius: 50px;
}
.carousel-indicators li.active {
    background: #000000;
}
h2.work-p-heading {
    width: 100%;
    float: left;
    text-align: center;
    color: var(--headingColor);
    font-size: 40px;
    line-height: 60px;
    font-family:  var(--interrMedium-fonts);
    padding-bottom: 48px;
}