.perx-page-wrap {
    width: 100%;
    float: left;
    margin-top: 86px;
    margin-bottom: 40px;
}
.perx-box-left-side {
    width: 100%;
    float: left;
}
h1.perx-heading-one {
    width: 100%;
    float: left;
    text-align: left;
    font-size: 42px;
    line-height: 66px;
    color: #4E24B6;
    font-family:  var(--interBold-fonts);
}
.perx-discribtion {
    width: 100%;
    float: left;
    text-align: left;
    font-size: 28px;
    color: #545454;
    line-height: 44px;
    font-family:  var(--interrRegular-fonts);
    margin-top: 26px;
}
.perx-illus-desk {
    width: 100%;
    float: left;
    animation: myfirst 5s infinite;
}
.perx-illus-desk img {
    width: 100%;
}
.perx-why {
    width: 100%;
    float: left;
    background: #F9FAFA;
    padding-top: 46px;
    padding-bottom: 42px;
}
h1.prex-heading-two {
    width: 100%;
    float: left;
    text-align: center;
    color: #5714BD;
    font-size: 36px;
    line-height: 54px;
    font-family:  var(--interrMedium-fonts);
    margin-bottom: 45px;
}
.perx-card-box {
    width: 100%;
    float: left;
}
.perx-icon-box {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 28px;
}
.perx-icon-box img {
    width: 145px;
}
.perx-card-describe-box {
    width: 100%;
    float: left;
}
h4.perx-card-heading {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    color: #4E24B6;
    font-family:  var(--interrSemiBold-fonts);
    margin-bottom: 18px;
}
.perx-describtion-card {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 22px;
    line-height: 34px;
    color: #545454;
    font-family:  var(--interrRegular-fonts);
}
.perx-sction{
    width: 100%;
    float: left;
}
.perx-illus-mobile {
    width: 100%;
    float: left;
    text-align: center;
}
.perx-illus-mobile img {
    width: 94%;
}
section#schedule .njt-conctact-body {
    margin-top: 0px !important;
}
section#schedule h2.thanks-title {
    color: #4E24B6 !important;
}

/* Mobile Responsive */
@media only screen and (max-width: 767px) {
    section#schedule .ltai_container .ltai_input_container textarea.ltai_input {
        font-size: 12px !important;
        line-height: 18px !important;
    }
    .perx-illus-mobile {
        display:block;
    }
    .perx-illus-desk{
        display:none;
    }
    h1.perx-heading-one {
        font-size: 30px;
        line-height: 40px;
        text-align: center;
    }
    .perx-discribtion {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        margin-top: 10px;
    }
    .perx-page-wrap {
        margin-top: 30px;
        margin-bottom: 40px;
    }
    h1.prex-heading-two {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
    }
    h4.perx-card-heading {
        font-size: 20px;
        line-height: 32px;
    }
    .perx-describtion-card {
        font-size: 18px;
        line-height: 24px;
    }
    .perx-card-box {
        margin-bottom: 30px;
    }
    a.perx-footer-link {
        font-size: 12px;
        line-height: 18px;
    }
    .perx-logo-copy {
        font-size: 12px;
        line-height: 18px;
    }
    .perx-logo-footer {
        width: 100%;
        float: left;
        text-align: left;
        margin-bottom: 20px;
    }
    .perx-logo-footer img {
        width: 32px;
    }

    .perx-why {
        padding-top: 24px;
        padding-bottom: 20px;
    }
    .perx-schdule {
        margin-top: 24px;
        margin-bottom: 20px;
    }
    .perx-logo img {
        width: 32px;
    }
    a.perx-link-nav {
        padding: 5px 2px;
        font-size: 12px;
    }
    span.perx-logo-txt {
        font-size: 12px;
        padding-left: 10px;
    }
  }
  @media only screen and (min-width: 768px) {
    .perx-illus-mobile {
        display:none
    }
    .perx-illus-desk{
        display:block;
    }
  }