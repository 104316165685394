.t-why-wrap {
    width: 100%;
    float: left;
    background: url("../../Assets/Images/oval.png");
    background-repeat: no-repeat;
    background-position-x: -280px;
    background-position-y: bottom;
    background-size: auto;
    padding-top: 160px;
    padding-bottom: 80px;
}
h2.why-heading {
    font-size: 40px;
    line-height: 60px;
    width: 100%;
    float: left;
    text-align: center;
    color: var(--headingColor);
    font-family:  var(--interrMedium-fonts);
    padding-bottom: 150px;
}
.why-txt-box {
    width: 100%;
    float: left;
    margin-top: 50px;
}
.why-heading-txt {
    width: 100%;
    float: left;
    font-size: 32px;
    line-height: 48px;
    color: var(--headingColor);
    font-family:  var(--interrMedium-fonts);
}
hr.line-1 {
    width: 50%;
    float: left;
    border: solid 2px var(--primaryColor);
}
.why-discribtion {
    width: 100%;
    float: left;
    font-size: 24px;
    line-height: 36px;
    font-family:  var(--interrRegular-fonts);
    color: #000;
}
.why-img {
    width: 100%;
    float: left;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.why-img img {
    width: 94%;
}
.t-why-wrap-2 {
    width: 100%;
    float: left;
    padding-bottom: 224px;
    background: url("../../Assets/Images/oval.png");
    background-repeat: no-repeat;
    background-position-x: 129%;
    background-position-y: top;
    background-size: auto;
    padding-top: 90px;
}