@font-face {
  font-family: 'Material Icons', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url(../Assets/Fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(../Assets/Fonts/MaterialIcons-Regular.woff2) format('woff2'),
    url(../Assets/Fonts/MaterialIcons-Regular.woff) format('woff'),
    url(../Assets/Fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/******************************************************************/
/******************************************************************/
/*********************** TransitPerx fonts ************************/
/******************************************************************/
/******************************************************************/
@font-face {
  font-family: 'inter-bold';
  src: url('../Assets/Fonts/inter-bold-slnt-0.eot');
  src: url('../Assets/Fonts/inter-bold-slnt-0.woff') format('woff'),
    url('../Assets/Fonts/inter-bold-slnt-0.ttf') format('truetype'),
    url('../Assets/Fonts/inter-bold-slnt-0.svg#inter-bold') format('svg');
}
@font-face {
  font-family:  'inter-medium';
  src: url('../Assets/Fonts/inter-medium-slnt-0.eot');
  src: url('../Assets/Fonts/inter-medium-slnt-0.woff') format('woff'),
    url('../Assets/Fonts/inter-medium-slnt-0.ttf') format('truetype'),
    url('../Assets/Fonts/inter-medium-slnt-0.svg#inter-medium') format('svg');
}
@font-face {
  font-family: 'inter-regular';
  src: url('../Assets/Fonts/inter-regular-slnt-0.eot');
  src: url('../Assets/Fonts/inter-regular-slnt-0.woff') format('woff'),
    url('../Assets/Fonts/inter-regular-slnt-0.ttf') format('truetype'),
    url('../Assets/Fonts/inter-regular-slnt-0.svg#inter-regular') format('svg');
}
@font-face {
  font-family: 'inter-semibold';
  src: url('../Assets/Fonts/inter-semibold-slnt-0.eot');
  src: url('../Assets/Fonts/inter-semibold-slnt-0.woff') format('woff'),
    url('../Assets/Fonts/inter-semibold-slnt-0.ttf') format('truetype'),
    url('../Assets/Fonts/inter-semibold-slnt-0.svg#inter-semibold')
      format('svg');
}



html {
  overflow-x: hidden;
}

body {
  font-family:  var(--interrRegular-fonts);
  color: #9fa1a4;
  line-height: 1.5;
  padding: 0px;
  margin: 0px;
}
a {
  color: #777;
  transition: 0.3s all ease;
}

a:hover {
  color: #000;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-heading {
  font-family:  var(--interrMedium-fonts);
}
div#navbarSupportedContent nav.site-navigation.position-relative.nav-menu {
  width: 100%;
  float: left;
}
ul.navbar-nav.mr-auto {
  width: 100%;
  float: left;
  justify-content: flex-end;
}
ul.navbar-nav li {
  padding: 9px 14px !important;
}
.container {
  z-index: 2;
  position: relative;
}
header.site-navbar.js-sticky-header.site-navbar-target.shrink {
  position: fixed;
  top: 0px;
  background: #dfe6e9 !important;
  z-index: 99999;
}
h2.app-main-heading {
  color: #292a2a !important;
  font-size: 50px;
  line-height: 76px;
}
.text-app {
  font-size: 24px;
  font-family:  var(--interrRegular-fonts);
  color: #292a2a;
  line-height: 36px;
}
img.image-app-l {
  width: 140px;
}
.sign-heading1 {
  width: 100%;
  float: left;
  text-align: center;
  padding: 30px 0px;
}
h2.heading {
  margin-bottom: 0px;
  font-size: 28px;
 font-family:  var(--interBold-fonts);
  line-height: 39px;
  color: #fff;
}
.sign-page {
  background: #292a2a;
  width: 100%;
  float: left;
}
.handle-box-2 {
  width: 100%;
  float: left;
}
.footer-link {
  width: 100%;
  float: left;
  text-align: left;
  margin-bottom: 20px;
}
.footer-link a {
  color: var(--primaryColor) !important;
  font-size: 18px;
  font-family:  var(--interrSemiBold-fonts);
}
h1.number-txt {
  width: 100%;
  float: left;
  font-size: 70px;
  line-height: 112px;
  color: #ff5252 !important;
 font-family:  var(--interBold-fonts);
}
h3.inner-heading {
  width: 100%;
  float: l;
  font-size: 28px;
  line-height: 45px;
  color: #292a2a;
 font-family:  var(--interBold-fonts);
}
.signing-section {
  width: 100%;
  float: left;
  padding-bottom: 100px;
}
a.link1.google img.image-app-l {
  width: 156px;
  margin-left: 10px;
}
.app-link-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
}
img.image1 {
  width: 100%;
}
.njt-image-2 img {
  width: 100%;
}
.download-section {
  width: 100%;
  float: left;
  padding-top: 45px;
  padding-bottom: 40px;
  background: #edf1fe;
}
.point-section {
  width: 100%;
  float: left;
  padding: 70px 0px;
}
.partner-section {
  width: 100%;
  float: left;
  text-align: center;
}
.partner-head {
  width: 100%;
  float: left;
  text-align: center;
}
h2.partner-h-1 {
  font-size: 28px;
 font-family:  var(--interBold-fonts);
  line-height: 39px;
  color: #ffffff;
  margin-bottom: 0px;
  padding-top: 120px;
  padding-bottom: 70px;
}
h1.partner-h-2 {
  font-size: 67px;
  color: var(--primaryColor);
  line-height: 108px;
 font-family:  var(--interBold-fonts);
}
.partner-head.margin {
  margin-top: 60px;
}
.bg-inner-section{
  margin-top: 90px;
  /* height: 400px; */
  width: 100%;
  background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-bottom: 100px;
}
.heading-one-promotion{
  font-size: 60px;
  font-family: 'inter-bold';
  color: #ffffff;
  margin-bottom: 0px;
}
.heading-box-b{
  padding-top: 80px;
}
.business-logo{
 height: 200px;
 width: 200px;
 margin-top: 50px;
}
.business-logo img{
  width: 100%;
  height: 100%;
}
footer.njt-footer {
  width: 100%;
  float: left;
  padding: 75px 0px;
  background: #292a2a;
}
.main-business-box{
  display:"flex";
  justify-content: flex-end;
  width: 100%;
  text-align: right;
}
.copy-right {
  font-size: 16px;
  color: #fff;
  font-family:  var(--interrRegular-fonts);
}
ul.footer-link {
  padding-left: 0px;
}
ul.footer-link li {
  text-decoration: none;
  list-style: none;
  font-size: 16px;
  font-family:  var(--interrSemiBold-fonts);
  line-height: 25px;
}
ul.footer-link li a {
  color: #fff;
}
.member-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  min-height: 152px;
  padding: 10px;
}
.h-img {
  height: 105px;
  justify-content: center;
}
/* Back to top button */

.back-to-top {
  position: fixed;
  display: none;
  background: var(--primaryColor);
  color: #fff;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 50%;
  right: 15px;
  bottom: 15px;
  z-index: 11;
}

.back-to-top i {
  color: #fff;
  font-size: 24px;
  position: absolute;
  top: 13px;
  left: 13px;
}

/* Sticky Wrapper */
img.logo-img {
  width: 70px;
}
.sticky-wrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
}

.sticky-wrapper .site-navbar {
  transition: 0.3s all ease;
}

.sticky-wrapper .site-navbar .site-menu-toggle {
  color: #fff;
}

.site-navbar .site-logo a {
  color: var(--primaryColor) !important;
  font-size: 16px;
  font-family:  var(--interrSemiBold-fonts);
}
.sticky-wrapper .site-navbar .site-menu > li > a {
  color: rgba(255, 255, 255, 0.8) !important;
}
.sticky-wrapper .site-navbar .site-menu > li > a:hover,
.sticky-wrapper .site-navbar .site-menu > li > a.active {
  color: var(--primaryColor) !important;
}
.sticky-wrapper.is-sticky .burger:before,
.sticky-wrapper.is-sticky .burger span,
.sticky-wrapper.is-sticky .burger:after {
  background: #000000;
  transition: 0s all ease;
}
.sticky-wrapper.is-sticky .site-navbar {
  background: #fff;
  border-bottom: 1px solid transparent;
  box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.2);
}
.home .sticky-wrapper.is-sticky .site-navbar {
  background: var(--backgroundColor) !important;
  border-bottom: 0px !important;
  box-shadow: none !important;
}
.site-navbar.shrink .site-menu > li > a {
  color: var(--primaryColor) !important;
}
site-navbar.shrink .site-menu > li > a:hover,
.site-navbar.shrink .site-menu > li > a.active {
  color: var(--primaryColor) !important;
}
.site-navbar.shrink .site-menu > li.active a {
  color: var(--primaryColor) !important;
}
.site-navbar.shrink
  ul.site-menu.main-menu.js-clone-nav.mr-auto.d-none.d-lg-block
  li
  a {
  color: var(--primaryColor) !important;
}

.sticky-wrapper .shrink {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/*--------------------------------------------------------------
# Header Section
--------------------------------------------------------------*/

.hero-section {
  background: var(--backgroundColor);
  position: relative;
}

.hero-section .wave {
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  bottom: -150px;
}

.hero-section .wave svg {
  width: 100%;
}

/* .hero-section,
.hero-section > .container > .row {
  height: 100vh;
  min-height: 880px;
} */

.hero-section.inner-page {
  height: 60vh;
  min-height: 0;
}

.hero-section.inner-page .hero-text {
  -webkit-transform: translateY(-150px);
  transform: translateY(-150px);
  margin-top: -120px;
}

.hero-section h1 {
  font-size: 3.5rem;
  color: #fff;
  font-weight: 700;
  margin-bottom: 30px;
}

.hero-section p {
  font-size: 18px;
  color: #fff;
}

.hero-section .iphone-wrap {
  position: relative;
}

.hero-section .iphone-wrap .phone-2,
.hero-section .iphone-wrap .phone-1 {
  position: absolute;
  top: -50%;
  overflow: hidden;
  left: 0;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  width: 250px;
}

.hero-section .iphone-wrap .phone-2 {
  margin-top: 50px;
  margin-left: 100px;
  width: 250px;
}

/* Burger */

.burger {
  width: 28px;
  height: 32px;
  cursor: pointer;
  position: relative;
  z-index: 99;
  float: right;
}

.burger:before,
.burger span,
.burger:after {
  width: 100%;
  height: 3px;
  display: block;
  background: #000;
  border-radius: 2px;
  position: absolute;
  opacity: 1;
}

.burger:before,
.burger:after {
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    transform 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    transform 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  content: '';
}

.burger:before {
  top: 4px;
}

.burger span {
  top: 15px;
}

.burger:after {
  top: 26px;
}

/* Hover */

.burger:hover:before {
  top: 7px;
}

.burger:hover:after {
  top: 23px;
}

/* Click */

.burger.active span {
  opacity: 0;
}

.burger.active:before,
.burger.active:after {
  top: 40%;
}

.burger.active:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=5);
  /*for IE*/
}

.burger.active:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-5);
  /*for IE*/
}

.burger:focus {
  outline: none;
}

/*--------------------------------------------------------------
# Nav
--------------------------------------------------------------*/

.site-navbar {
  margin-bottom: 0px;
  z-index: 1999;
  position: absolute;
  top: 0;
  width: 100%;
}

.site-navbar .site-logo {
  position: relative;
  font-size: 14px;
  font-family:  var(--interrSemiBold-fonts);
}

.site-navbar .site-logo a:hover {
  text-decoration: none;
}

.site-navbar .site-navigation .site-menu {
  margin-bottom: 0;
}

.site-navbar .site-navigation .site-menu a {
  text-decoration: none !important;
  display: inline-block;
}

.site-navbar .site-navigation .site-menu > li {
  display: inline-block;
  padding: 34px 10px;
}

.site-navbar .site-navigation .site-menu > li > a {
  padding: 7px 16px !important;
  display: inline-block;
  text-decoration: none !important;
  color: var(--menuColor) !important;
  font-size: 20px;
  font-family:  var(--interrMedium-fonts);
  /* border: solid 2px var(--primaryColor); */
  line-height: 32px;
}

.site-navbar .site-navigation .site-menu > li > a:hover {
  color: var(--primaryColor);
}
h1.fag-head {
  font-size: 41px;
  line-height: 61px;
  color: #000000;
  font-family:  var(--interrSemiBold-fonts);
  padding-bottom: 50px;
}

.site-navbar .site-navigation .site-menu .has-children {
  position: relative;
}

.site-navbar .site-navigation .site-menu .has-children > a {
  position: relative;
  padding-right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children > a:before {
  position: absolute;
  content: '\ea99';
  font-size: 16px;
  top: 52%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'icofont', sans-serif;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown {
  visibility: hidden;
  opacity: 0;
  top: 100%;
  position: absolute;
  text-align: left;
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  padding: 0px 0;
  margin-top: 15px;
  margin-left: 0px;
  background: #fff;
  transition: 0.2s 0s;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
  position: absolute;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown a {
  text-transform: none;
  letter-spacing: normal;
  transition: 0s all;
  color: #000000;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown .active {
  color: #2d71a1 !important;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 210px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
  padding: 9px 20px;
  display: block;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li
  > a:hover {
  background: #f2f4f6;
  color: #000000;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > a:before {
  content: '\ea75';
  right: 20px;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > .dropdown,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > ul {
  left: 100%;
  top: 0;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:hover
  > a,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:active
  > a,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:focus
  > a {
  background: #f2f4f6;
}

.site-navbar .site-navigation .site-menu .has-children:hover > a,
.site-navbar .site-navigation .site-menu .has-children:focus > a,
.site-navbar .site-navigation .site-menu .has-children:active > a {
  color: #2d71a1;
}

.site-navbar .site-navigation .site-menu .has-children:hover,
.site-navbar .site-navigation .site-menu .has-children:focus,
.site-navbar .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}

.site-navbar .site-navigation .site-menu .has-children:hover > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:focus > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
  transition-delay: 0s;
  margin-top: -10px;
  visibility: visible;
  opacity: 1;
}

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: #fff;
  height: calc(100vh);
  -webkit-transform: translateX(110%);
  transform: translateX(110%);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  transition: 0.3s all ease-in-out;
}

.offcanvas-menu .site-mobile-menu {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.site-mobile-menu .site-mobile-menu-header {
  width: 100%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
  float: right;
  margin-top: 8px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  font-size: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 0px;
  line-height: 1;
  cursor: pointer;
  transition: 0.3s all ease;
  margin-top: 20px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
  float: left;
  margin-top: 10px;
  margin-left: 0px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
  display: inline-block;
  text-transform: uppercase;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
  max-width: 70px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
  text-decoration: none;
}

.site-mobile-menu .site-mobile-menu-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0 20px 20px 20px;
  height: calc(100vh - 52px);
  padding-bottom: 150px;
}

.site-mobile-menu .site-nav-wrap {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.site-mobile-menu .site-nav-wrap a {
  padding: 10px 20px;
  display: block;
  position: relative;
}

.site-mobile-menu .site-nav-wrap a:hover {
  color: #2d71a1;
}

.site-mobile-menu .site-nav-wrap li {
  position: relative;
  display: block;
}

.site-mobile-menu .site-nav-wrap li.active > a {
  color: #2d71a1;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
  font-size: 12px;
  z-index: 20;
  font-family: 'icofont', sans-serif;
  content: '\ea99';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-180deg);
  transform: translate(-50%, -50%) rotate(-180deg);
  transition: 0.3s all ease;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.site-mobile-menu .site-nav-wrap > li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}

.site-mobile-menu .site-nav-wrap > li > a {
  padding-left: 20px;
  font-size: 20px;
}

.site-mobile-menu .site-nav-wrap > li > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.site-mobile-menu .site-nav-wrap > li > ul > li {
  display: block;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > a {
  padding-left: 40px;
  font-size: 16px;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul {
  padding: 0;
  margin: 0;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
  display: block;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
  font-size: 16px;
  padding-left: 60px;
}
.inner-container {
  width: 1000px;
  max-width: 100%;
  margin: 0px auto;
}
.hero-section.section-h-1 {
  padding-top: 230px;
  padding-bottom: 125px;
}
.section_faqs_wrap{
  padding-top: 230px;
}
.h-img img {
  height: 80px;
}
h5.member-in-head {
  font-size: 20px;
  line-height: 30px;
  color: #000;
  font-family:  var(--interrSemiBold-fonts);
}
.member-box:hover {
  /* border: solid 3px #AB3495;
  box-shadow: 1px 2px 5px #000; */
}
.faqs-accord {
  width: 100%;
  float: left;
  padding-top: 45px;
  padding-bottom: 25px;
}
.accordion > .card {
  border: 0px;
  border-radius: 0px;
}
.card .card-header {
  background: #fff;
  border-bottom: 0px;
  padding: 0px;
}
.btn-link:hover {
  color: var(--primaryColor) !important;
  text-decoration: none;
}
button.btn.btn-link.collapsed:focus {
  color: var(--primaryColor) !important;
  text-decoration: none;
}
button.btn.btn-link {
  font-size: 20px;
  color: #000;
  font-family:  var(--interrSemiBold-fonts);
  text-decoration: none !important;
  width: 100%;
  text-align: left;
  padding: 20px;
  border-radius: 0px;
}
.touch-form {
  width: 100%;
  float: left;
  background: #f2f6fa;
  padding-top: 110px;
  padding-bottom: 56px;
}

h2.get-touch-head {
  font-size: 46px;
  line-height: 66px;
  color: #000000 !important;
  font-family:  var(--interrSemiBold-fonts);
}
ul li.col-sm-3 .member-box.border.active {
  border: solid 3px var(--primaryColor) !important;
}
ul li.col-sm-3 .member-box.border {
  border: solid 3px #fff !important;
  box-shadow: 1px 2px 5px #000;
  text-decoration: none !important;
}
/* terms of use */

.term-of-use {
  padding-top: 152px;
}
.term-demi {
  width: 100%;
  float: left;
  font-size: 26px;
  line-height: 35px;
  color: #000000;
  font-family:  var(--interrSemiBold-fonts);
}
.term-demi.margin {
  margin: 20px 0px;
}
.terms-paragrap {
  font-size: 20px;
  line-height: 27px;
  font-family:  var(--interrRegular-fonts);
  color: #000000;
}
.terms-paragrap.margin {
  margin-bottom: 20px;
}
.list {
  width: 100%;
  float: left;
}
.list ul li {
  font-size: 20px;
  line-height: 27px;
  font-family:  var(--interrRegular-fonts);
  color: #000000;
  padding-bottom: 15px;
}
.list ol li {
  font-size: 20px;
  line-height: 27px;
  font-family:  var(--interrRegular-fonts);
  color: #000000;
  padding-bottom: 15px;
}
.heading-term-b {
  font-size: 20px;
  color: #000000;
  line-height: 27px;
  font-family:  var(--interBold-fonts);
}
.heading-term-b.margin {
  margin-bottom: 20px;
}
.term-user-box {
  width: 100%;
  float: left;
  padding-top: 50px;
}
section#contact {
  width: 100%;
  float: left;
}

/* Partner design */
.box-part-sec img {
  width: 80%;
}
.box-part-sec {
  width: 100%;
  float: left;
}
.top-partner {
  position: relative;
  width: 100%;
  float: left;
  /* margin-top: -122px; */
}
.call-join-bx {
  width: 100%;
  float: left;
  text-align: center;
  padding: 54px 0px;
}
.call-main-head {
  width: 100%;
  float: left;
  text-align: center;
}
h3.call-h3 {
  font-size: 36px;
  line-height: 45px;
  color: #fff;
  font-family: var(--interBold-fonts);
  font-weight: bold;
}
h4.call-h4 {
  font-size: 28px;
  line-height: 45px;
  color: #292a2a;
 font-family:  var(--interBold-fonts);
}
.link-call a {
  color: #0091ff;
  font-size: 22px;
  line-height: 26px;
 font-family:  var(--interBold-fonts);
  text-decoration: underline;
}
.partner-bg {
  width: 100%;
  background: url('../Assets/Images/partner-bg.jpg');
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 70px;
}
.link-call {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 20px;
}
.heading-box {
  width: 100%;
  float: left;
  /* background: #292A2A; */
}
.part-img {
  width: 360px;
  float: right;
}
.part-img img {
  width: 100%;
}
.box-part-njt {
  width: 100%;
  float: left;
  background: #f3edfe;
  padding-top: 30px;
  padding-bottom: 30px;
}
.parntner-sub-box {
  width: 100%;
  float: left;
  text-align: left;
  padding-top: 20px;
}
h3.partner-sub-head {
  font-size: 28px;
  line-height: 45px;
  width: 100%;
  float: left;
  color: #292a2a !important;
}
.para-part {
  font-size: 16px;
  line-height: 26px;
  color: #484848;
  font-family:  var(--interrSemiBold-fonts);
  padding-top: 30px;
  padding-bottom: 44px;
  width: 100%;
  float: left;
}
.btn-link-part {
  width: 100%;
  float: left;
  padding-top: 10px;
}
.btn-link-part a {
  font-size: 20px;
 font-family:  var(--interBold-fonts);
  color: #fff;
  background: var(--primaryColor);
  border-radius: 11px;
  padding: 6px 40px;
  border: solid 2px var(--primaryColor);
}
.btn-link-part a:hover {
  text-decoration: none;
  background: #fff;
  color: var(--primaryColor);
}
.heading-box-part {
  text-align: center;
  width: 100%;
  float: left;
}
h1.heading-part {
  font-size: 28px;
  line-height: 45px;
  color: #292a2a;
  padding-top: 46px;
  padding-bottom: 40px;
}
.align-center {
  text-align: center;
}
div#sign-up {
  width: 100%;
  float: left;
  background: #edf1fe;
  padding-top: 45px;
}
.box-par-head {
  width: 100%;
  float: left;
}
h3.head-paert {
  font-size: 28px !important;
  line-height: 39px;
 font-family:  var(--interBold-fonts);
  margin-bottom: 28px;
}
.call-box2 {
  width: 100%;
  float: left;
  position: sticky;
}
.input-b {
  width: 100%;
  float: left;
}
.input-b .li_container {
  display: block;
}
.input-b label.li_label {
  width: 100%;
  float: left;
  margin-bottom: 0px;
  padding-left: 0px;
  color: var(--labelField);
  font-size: 24px;
  font-family:  var(--interrRegular-fonts);
  line-height: 36px;
  font-weight: unset !important;
}
.input-b .li_input_container {
  margin-left: 0px !important;
  width: 100%;
  float: left;
}
.input-b input.li_input {
  width: 100% !important;
  float: left;
}
.input-b .ltai_container {
  display: block;
}
.input-b .ltai_input_container {
  margin-left: 0px !important;
  width: 100%;
  float: left;
}

.help-form-btn {
  width: 100%;
  float: left;
  text-align: center;
}
button.help-submit-btn {
  background: var(--primaryColor);
  border: solid 2px var(--primaryColor);
  color: #fff;
  border-radius: 9px;
  padding: 9px 18px;
  font-size: 24px;
  font-family:  var(--interrMedium-fonts);
  line-height: 36px;
}
button:focus {
  outline: none !important;
}
.input-b .clddi_input_container {
  display: block;
}
.input-b label.clddi_label {
  margin-bottom: 0px !important;
  padding-left: 0pc;
  width: 100%;
  float: left;
  font-size: 24px;
  line-height: 36px;
  color: var(--labelField);
  font-family:  var(--interrRegular-fonts);
  font-weight: unset !important;
}
.input-b .clddi_div1 {
  margin-left: 0px !important;
  width: 100%;
  float: left;
}
.input-b .clddi_container {
  width: 100% !important;
  float: left;
  background: #fff;
  border-radius: 4px;
}
.input-b.margin {
  margin-top: 16px;
}
.help-form-btn.margin {
  text-align: right !important;
}

.clddi_input_container .clddi_div1 .clddi_container .clddi_input {
  padding: 8px 10px !important;
}
.loading-b {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 50px; */
}
section#section1 {
  width: 100%;
  float: left;
}
ul.navbar-nav li.nav-item a {
  font-size: 24px;
  line-height: 36px;
  font-family:  var(--interrMedium-fonts);
  border-radius: 8px;
  /* width: 120px; */
  text-align: center;
  padding: 4px 16px !important;
}
ul.navbar-nav.mr-auto {
  align-items: center;
}
ul.navbar-nav li.nav-item a.nav-link.border {
  border: solid 2px #ab3495 !important;
}
.njt-logo-header a.navbar-brand img {
  height: 80px;
}.njt-footer-log img
ul.navbar-nav li a.nav-link.bg-color {
  border: solid 2px #ab3495;
  background: #ab3495;
  color: #fff;
}
nav.navbar.navbar-expand-lg.fixed-top {
  width: 100%;
  float: left;
  background: #dfe6e9 !important;
}
ul.navbar-nav li a.nav-link.bg-color {
  border: 2px solid #ab3495;
  background: #ab3495;
  color: #fff !important;
}
.input-b textarea.ltai_input {
  box-sizing: border-box;
  border: 1px solid var(--primaryColor) !important;
  display: inline-block;
  font-size: 20px !important;
  line-height: 30px !important;
  outline: none;
  height: 48px;
  color: #646464 !important;
  border-radius: 4px !important;
  padding: 10px;
  font-family:  var(--interrRegular-fonts);
  width: 100% !important;
  float: left;
}
.input-b label.ltai_label {
  width: 100%;
  float: left;
  margin-bottom: 0px;
  padding-left: 0px;
  color: var(--labelField);
  font-size: 24px;
  font-family:  var(--interrRegular-fonts);
  line-height: 36px;
  font-weight: unset !important;
  margin-top: 20px;
}
.input-b.txt-area p.ltai_label {
  display: block !important;
}


@media screen and (max-width: 767px) {
  .heading-box-b.deskstop{
    display: none;
  }
  .heading-box-b.mobile{
    display: block;
  }
  .heading-one-promotion{
    font-size: 35px;
  }
  .main-business-box{
    display: none;
  }
  .business-logo{
    height: 80px;
    width: 80px;
    margin-top: 10px;
   }
   .bg-inner-section{
    margin-top: 66px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-size: cover;
    background: #4e76b2 !important;
   }
   .heading-box-b {
    padding-top: 30px;
    }
  .li_container .li_input_container .li_input {
    border-radius: 2px !important;
  }
  .input-b .clddi_container {
    border-radius: 2px !important;
  }
  .clddi_input_container .clddi_div1 .clddi_container select.clddi_input {
    border-radius: 2px !important;
  }
  .input-b label.li_label {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .input-b label.input-lab {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .input-b label.ltai_label {
    font-size: 16px;
    line-height: 24px;
    margin-top: 0px;

  }
  .input-b.margin input.form-control {
    border-radius: 2px !important;
  }
  .ltai_container .ltai_input_container textarea.ltai_input {
    border-radius: 2px !important;
  }
  .njt-contact-wrap .input-b label.ltai_label {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .njt-faq-body .input-b label.ltai_label {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .input-b label.clddi_label {
    font-size: 16px;
    line-height: 24px;
  }
  .input-b.margin {
    margin-top: 2px;
  }
  .label-box-name {
    margin-top: 2px !important;
  }
  .btn-cc {
    margin-top: 12px;
  }
  .btn-cc .help-form-btn.margin {
    margin-top: 12px;
  }
  .inner-label {
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .box-part-sec img {
    width: 90%;
  }
  .form-b-touch.help button.help-submit-btn {
    margin-bottom: 80px;
  }
  .box-part-sec .col-sm-6 {
    margin-bottom: 30px;
  }
  button.help-submit-btn {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 14px;
    border-radius: 6px;
  }
  .njt-sign-body .get-box {
    padding-top: 4px !important;
    padding-bottom: 40px !important;
  }
  .part-img {
    width: 100%;
  }
  h1.partner-h-2 {
    font-size: 24px !important;
    line-height: 58px !important;
  }
  .njt-hadle-box {
    text-align: center;
  }
  .hero-section.section-h-1 {
    padding-top: 25px;
    padding-bottom: 0px;
    height: 250px;
  }
  .section_faqs_wrap{
    padding-top: 80px !important;
    height: 100% !important;
    padding-bottom: 30px;
  }
  h1.mb-0.site-logo a span {
    display: none;
  }
  h2.app-main-heading {
    font-size: 20px;
    line-height: 30px;
  }
  .mob-res {
    width: 190px;
    margin: 0px auto;
  }
  h2.heading {
    font-size: 12px;
  }
  h1.number-txt {
    font-size: 29px;
    line-height: 48px;
    width: 8%;
    float: left;
  }
  h3.inner-heading {
    font-size: 14px;
    line-height: 24px;
    padding-top: 7px;
  }
  .text-app {
    width: 100%;
    float: left;
    text-align: justify;
    font-size: 12px;
    line-height: 15px;
  }
  .app-link-box {
    justify-content: center;
    width: 100%;
  }
  img.image-app-l {
    width: 80px;
  }
  a.link1.google img.image-app-l {
    width: 90px;
  }
  .njt-image-2.responsive {
    display: none;
  }

  .signing-section {
    padding-bottom: 25px;
  }
  .download-section {
    padding: 25px 0px;
  }
  .point-section {
    padding: 25px 0px;
  }
  .partner-section {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  h2.partner-h-1 {
    padding-top: 60px;
    padding-bottom: 0px;
  }
  .partner-head.margin {
    font-size: 24px;
    margin-top: 40px;
  }
  footer.njt-footer {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 60px;
  }
  .copy-right {
    font-size: 12px;
    padding-bottom: 45px;
  }
  ul.footer-link li {
    font-size: 12px;
  }
  .njt-note-box {
    margin-top: 20px !important;
    margin-bottom: 40px !important;
  }
  /* FAQs */
  h1.fag-head {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 0px;
    padding-bottom: 34px;
  }
  .faqs .hero-section.section-h-1 {
    padding-top: 80px !important;
    height: 100% !important;
    padding-bottom: 30px;
  }
  .member-box {
    flex-direction: row;
    justify-content: flex-start;
    min-height: 53px;
    align-items: center;
    box-shadow: none;
  }
  .h-img {
    width: 44px;
    height: inherit;
    margin-right: 13px;
  }
  .member-box.border {
    margin-bottom: 18px;
  }
  button.btn.btn-link {
    font-size: 16px;
  }
  .faqs_mean_box button.accordion-button{
    font-size: 16px !important;
    line-height: 24px;
  }
  div#accordionFlushExample .accordion-body {
    font-size: 16px !important;
    line-height: 24px;
  }
  .faqs_mean_box .accordion-body{
    font-size: 12px!important;
    line-height: 16px!important;
  }
  h2.get-touch-head {
    font-size: 20px;
    line-height: 30px;
  }
  .touch-form {
    padding-top: 70px;
    padding-bottom: 0px;
  }

  /* Terms of Use */

  .term-demi {
    font-size: 12px;
    line-height: 16px;
  }
  .terms-paragrap {
    font-size: 12px;
    line-height: 16px;
  }
  .list ul li {
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 10px;
  }
  .list ol li {
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 10px;
  }
  .heading-term-b {
    font-size: 12px;
    line-height: 16px;
  }
  .terms-paragrap.margin {
    margin-bottom: 10px;
  }
  .term-of-use {
    padding-top: 80px;
  }
  .partner-bg {
    background: url('../Assets/Images/part-mobile.png') !important;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  iframe#JotFormIFrame-201693758229061 {
    min-height: 1000px !important;
  }
  .part-img {
    width: 90%;
  }
  h3.partner-sub-head {
    font-size: 24px;
    line-height: 40px;
  }
  .touch-form {
    padding-top: 70px;
  }
  img.logo-img {
    width: 60px !important;
  }
  .site-navbar .site-navigation .site-menu > li > a {
    font-size: 13px;
  }
  a.mb-0 {
    font-size: 10px !important;
  }
  h2.app-main-heading {
    font-size: 25px;
    line-height: 38px;
  }
  .text-app {
    font-size: 16px;
    line-height: 26px;
  }
  .hero-section.section-h-1 {
    padding-top: 150px;
    padding-bottom: 90px;
  }
  .section_faqs_wrap{
    padding-top: 150px;
    padding-bottom: 10px;
  }
  h2.heading {
    font-size: 22px;
    line-height: 39px;
  }
  h1.number-txt {
    font-size: 40px;
    line-height: 60px;
  }
  h3.inner-heading {
    font-size: 20px;
    line-height: 45px;
  }
  h2.partner-h-1 {
    font-size: 30px;
  }
  h1.partner-h-2 {
    font-size: 61px;
    line-height: 58px;
  }
  .copy-right {
    font-size: 12px;
  }
  ul.footer-link li {
    font-size: 12px;
  }

  /* FAQs */

  h1.fag-head {
    font-size: 30px;
    line-height: 46px;
    margin-bottom: 0px;
  }
  .site-wrap.home.faqs .section-h-1 {
    padding-bottom: 60px;
  }
  h5.member-in-head {
    font-size: 16px;
    line-height: 25px;
  }
  h2.get-touch-head {
    font-size: 30px;
    line-height: 46px;
  }
}

/******************* TransitPerx ********************************/
h1.mb-0.transit-logo a span {
  font-family:  var(--interrSemiBold-fonts);
  font-size: 24px;
  line-height: 48px;
  color: var(--labelField);
}
h1.mb-0.transit-logo p a {
  pointer-events: none;
  color: var(--primaryColor) !important;
}
header#header ul.transit-links li.signup a.nav-link {
  background: var(--labelField) !important;
  border-radius: 8px !important;
  color: var(--whiteColor) !important;
  font-family:  var(--interrMedium-fonts) !important;
}
header#header ul.transit-links li a {
  /* background: transparent !important; */
  border: 0px !important;
  /* color: var(--allTextColor) !important; */
  text-transform: capitalize !important;
  /* line-height: 30px; */
  font-family: var(--interrMedium-fonts);
}
header.trasit-header {
  background: #fff;
}
ul.transit-links li {
  padding: 10px 10px !important;
}
h2.transit-heading1 {
  font-size: 38px;
  line-height: 58px;
  font-family:  var(--interBold-fonts);
  color: var(--headingColor);
}
.hero-section.transit-section-1 {
  padding-top: 176px;
  width: 100%;
  float: left;
  padding-bottom: 78px;
}
.partner-button1 {
  width: 100%;
  float: left;
  margin-top: 64px;
}
.partner-button1 a {
  background: var(--primaryColor);
  border-radius: 11px;
  color: #fff;
  font-size: 20px;
  border: solid 2px var(--primaryColor);
  padding: 14px 20px;
  font-family:  var(--interrMedium-fonts);
  /* width: 100%; */
  /* float: left; */
  text-align: center;
}
.partner-button1.margin {
  margin-left: 30px;
}
.partner-button1 a:hover {
  text-decoration: none;
  color: var(--primaryColor);
  background: #fff;
}
.card-link a:hover {
  text-decoration: none;
  background: #fff;
  color: var(--primaryColor);
}
.two-btn {
  display: flex;
  flex-direction: row;
}
.transit-text-app {
  font-size: 24px;
  color: #000;
  line-height: 36px;
  font-family:  var(--interrRegular-fonts) !important;
  margin-top: 20px;
}
.transit-text-app p {
  font-size: 24px;
  color: #000;
  line-height: 36px;
  font-family:  var(--interrRegular-fonts) !important;
  margin-top: 20px;
  text-align: left;
}
.sign-page.trasit-sign {
  background: #4a4a4a;
}
.sign-page.trasit-sign h2.heading {
  font-size: 24px;
  line-height: 36px;
}
.eyeballs-box {
  width: 100%;
  float: left;
  padding: 60px 0px;
}
h3.transit-heading3 {
  text-align: center;
  width: 100%;
  float: left;
  margin-bottom: 0px;
  font-size: 32px;
  line-height: 48px;
  color: var(--allTextColor);
 font-family:  var(--interBold-fonts);
}
h3.transit-heading3 a {
  color: var(--primaryColor);
 font-family:  var(--interBold-fonts);
  pointer-events: none;
}
.box-counter {
  width: 100%;
  float: left;
  text-align: center;
  padding-top: 28px;
}
.counter-main {
  width: 100%;
  display: flex;
  justify-content: center;
}
.counter-main span {
  font-size: 48px;
  line-height: 72px;
 font-family:  var(--interBold-fonts);
  color: var(--primaryColor);
}
span.plus-counter {
  padding-left: 10px;
}
.counter-1 span.k-counter {
  display: none;
}
.counter-1 span.plus-counter {
  display: none;
}
.counter-inner-text {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 32px;
  line-height: 48px;
  color: var(--allTextColor);
  font-family:  var(--interrMedium-fonts);
}
.trasit-third-section {
  background: var(--backgroundColor);
  width: 100%;
  float: left;
  padding-top: 60px;
  padding-bottom: 78px;
}
.transit-img-handle {
  width: 100%;
  float: left;
}
.transit-img-handle img {
  width: 100%;
}
.crousel-inner-box {
  width: 100%;
  float: left;
}
.crousel-heading {
  width: 100%;
  float: left;
  text-align: left;
}
h4.corusel-h4 {
  font-size: 32px;
  line-height: 48px;
  color: #707070;
  font-family: var(--interrRegular-fonts) !important;
}
li.slide.selected {
  background: var(--backgroundColor) !important;
  margin-top: 60px;
}
li.slide {
  background: var(--backgroundColor) !important;
}
h4.corusel-h4 a {
  color: var(--primaryColor);
 font-family:  var(--interBold-fonts);
  pointer-events: none;
}
p.carousel-status {
  display: none;
}
.crousel-txt-box {
  width: 100%;
  float: left;
  text-align: left;
  color: var(--allTextColor);
  font-size: 24px;
  line-height: 36px;
  font-family: var(--interrRegular-fonts)!important;
  margin-top: 16px;
}
.carousel .control-dots .dot {
  background: var(--primaryColor) !important;
  border: solid 1px var(--primaryColor) !important;
}
ul.control-dots {
  text-align: left !important;
}
.box-crousel {
  background: var(--backgroundColor);
  min-height: 300px;
  width: 100%;
  float: left;
}
.carousel.carousel-slider .control-arrow:hover {
  background: transparent !important;
}
.partnership-box {
  width: 100%;
  float: left;
  text-align: center;
}
.fourth-section4 {
  background: #fff;
  width: 100%;
  float: left;
  padding-top: 60px;
  padding-bottom: 78px;
}
h3.partner-heading-3 {
  font-size: 32px;
  line-height: 48px;
  color: #707070;
 font-family:  var(--interBold-fonts);
}
.partner-txt-box-ship span {
 font-family:  var(--interBold-fonts);
}
h3.partner-heading-3 a {
  color: var(--primaryColor);
}
.partner-txt-box-ship {
  font-size: 24px;
  line-height: 36px;
  color: var(--allTextColor);
  font-family: var(--interrRegular-fonts) !important;
  margin-top: 32px;
}
.card-box-p {
  width: 90%;
  float: left;
  border: 2px solid var(--primaryColor);
  border-radius: 20px;
  text-align: center;
}
.card-inn-box {
  /* padding: 20px; */
  width: 100%;
  float: left;
}
.card-heading-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
}
h4.card-head {
  width: 150px;
  height: 40px;
  align-items: center;
  background: #c5c5c5;
  border-radius: 40px;
  font-size: 24px;
  line-height: 36px;
  padding-top: 4px;
  margin-bottom: 14px;
}
.card-tab-head {
  width: 100%;
  float: left;
}
.card-table-left {
  width: 50%;
  float: left;
  font-size: 16px;
  line-height: 24px;
  color: var(--allTextColor);
  padding: 10px 0px;
}
.card-table-left.head {
  font-family:  var(--interrSemiBold-fonts);
}
.card-table-left.body {
  font-family:  var(--interrRegular-fonts);
}
.card-tab-head.bg {
  background: #f1f1f1;
}
.card-link a {
  padding: 13px 38px;
  background: var(--primaryColor);
  border: solid 2px var(--primaryColor);
  color: #fff;
  border-radius: 11px;
  font-size: 20px;
 font-family:  var(--interBold-fonts);
}
button.help-submit-btn:hover {
  background: #fff;
  color: var(--primaryColor);
}
.shedule-call-btn .link:hover {
  text-decoration: none;
  background: #fff;
  color: var(--primaryColor);
}
.card-link {
  width: 100%;
  float: left;
  padding: 35px 0px;
}
.card-box1 {
  width: 100%;
  float: left;
  margin-top: 60px;
}
.italic-txt {
  width: 100%;
  float: left;
  text-align: center;
  font-family:  var(--interrRegular-fonts);
  font-style: italic;
  font-size: 24px;
  line-height: 36px;
  color: var(--allTextColor);
  padding-top: 32px;
}
.fifth-section5 {
  background: url("../Assets/Images/Background12.png");
  padding-top: 120px;
  padding-bottom: 78px;
  width: 100%;
  float: left;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
h3.faq-heading {
  font-family:  var(--interrMedium-fonts);
  text-align: center;
  margin-bottom: 0px;
  color: var(--headingColor);
  font-size: 40px;
  line-height: 60px;
}
.faq-new-design h2 {
  display: flex;
  align-items: center;
}
.faq-new-design h2.mb-0.border-top {
  border-top: solid 1px #4a4a4a !important;
}
.card:last-child {
  border-bottom: solid 1px #4a4a4a;
}
.card {
  background: transparent;
}
.faq-new-design {
  width: 100%;
  float: left;
}
.faq-new-design div.collapse {
  background: transparent;
}
div#accordionFlushExample h2.accordion-header button.accordion-button {
  color: #2e3137 !important;
  font-family:  var(--interrMedium-fonts) !important;
  line-height: 36px;
  font-size: 24px;
}
.faq-new-design .card .card-header {
  background: transparent;
}
div#accordionFlushExample .accordion-body {
  line-height: 36px;
  font-family:  var(--interrRegular-fonts) !important;
  font-size: 24px;
  color: #2e3137;
}
.faq-new-design div.faqs-accord {
  padding-top: 60px;
}
.faq-new-design .collapsing {
  background: transparent !important;
}
.partner-head.transit {
  width: 100%;
  display: flex;
  align-items: center;
}
.trasit-partner h2 {
  font-size: 40;
  line-height: 60px;
  color: var(--headingColor);
  font-family:  var(--interrMedium-fonts);
}
.trasit-partner h2.partner-h-1 a {
  color: var(--primaryColor);
  pointer-events: none;
}
.trasit-partner {
  width: 80%;
  float: left;
}
.shedule-call-btn {
  width: 20%;
  float: right;
  text-align: center;
}
.shedule-call-btn .link {
  background: var(--primaryColor);
  color: #fff;
  border-radius: 11px;
  font-size: 20px;
  line-height: 18px;
  font-family:  var(--interrMedium-fonts);
  padding: 13px 20px;
  border: solid 2px var(--primaryColor);
  cursor: pointer;
}

h2.partner-h-line {
  font-size: 24px;
  line-height: 36px;
  color: #2e3137;
  font-family:  var(--interrSemiBold-fonts) !important;
}
footer.t-footer-wrap {
  width: 100%;
  float: left;
  padding-top: 24px;
  padding-bottom: 28px;
}

.t-footer-copy {
  width: 100%;
  float: left;
  text-align: left;
}

.t-copy {
  color: #2e3137;
  font-size: 24px;
  line-height: 36px;
  font-family:  var(--interrRegular-fonts);
}
.tsocail-link {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
}
ul.t-links-footer {
  display: flex;
  align-items: flex-end;
  text-align: right;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 0px;
}
ul.t-links-footer li {
  list-style: none;
  margin: 0px 10px;
}
.get-box {
  width: 100%;
  float: left;
  padding-top: 60px;
  padding-bottom: 78px;
}
.check-2 {
  width: 100%;
  float: left;
}
.box-cc {
  width: 100%;
  float: left;
}
.btn-cc {
  width: 100%;
  float: left;
  margin-top: 24px;
}
.help-form-btn.call-sh.margin {
  width: 100%;
  float: left;
  margin-top: 24px;
}
.check-2-box {
  width: 100%;
  float: left;
  margin-bottom: 30px;
}
.check-2-box label {
  color: var(--labelField);
}
.coupon-b-wrap {
  width: 100%;
  float: left;
  background: #f7f7f7;
  padding: 60px 0px;
  margin-bottom: 50px;
}
h2.heading-banner {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 32px;
  line-height: 48px;
  color: #707070;
 font-family:  var(--interBold-fonts);
  margin-bottom: 30px;
}
.option-b {
  width: 100%;
  float: left;
  text-align: center;
  background: #fff;
  border: solid 2px var(--primaryColor);
  border-radius: 40px;
  margin-top: 40px;
}
.opt-head {
  width: 100%;
  float: left;
  padding: 16px 20px;
}
.partner-head.transit.txt-cc .trasit-partner {
  width: 100% !important;
}
button.btn.btn-link span.material-icons {
  float: right;
}
.auto-label {
  width: 100%;
  float: left;
  font-size: 20px;
  color: var(--labelField);
  font-family:  var(--interrSemiBold-fonts);
  padding-bottom: 8px;
  margin-top: 30px;
}

/***** Rider Page *******/
.rider-wrap {
  width: 100%;
  float: left;
}
.rider-wrap2 {
  width: 100%;
  float: left;
}
.rider-wrap h1 span {
  color: #375a8d !important;
}
.rider-sec-warp-2 {
  width: 100%;
  float: left;
}
.rider-sec-warp-2 .hero-section.transit-section-1 {
  background: #375a8d;
}
.rider-sec-warp-2 .transit-text-app {
  color: #fff;
}
.rider-sec-warp-2 .partner-button1 a {
  background: #2e86de;
  border-color: #2e86de;
}
.rider-sec-warp-2 .partner-button1 a:hover {
  background: transparent;
  color: #2e86de;
}
.ill-box {
  width: 100%;
  float: left;
}
.ill-box img {
  width: 100%;
}
.mobile-img {
  /* width: 100%;
  float: left; */
  margin-bottom: 20px;
}
button.btn.btn-link.collapsed span.material-icons.plus {
  display: block;
}
button.btn.btn-link.collapsed span.material-icons.remove {
  display: none;
}
button.btn.btn-link span.material-icons.plus {
  display: none;
}
button.btn.btn-link span.material-icons.remove {
  display: block;
}
.detailed-touch-b {
  width: 100%;
  float: left;
}
.det-touch-txt {
  width: 100%;
  float: left;
  font-family:  var(--interrRegular-fonts);
  font-style: italic;
  color: var(--labelField);
  font-size: 20px;
}
.det-touch-txt a {
  color: var(--primaryColor);
  cursor: pointer;
}
.box-p-p-p {
  width: 100%;
  float: left;
}
.box-p-p-p p {
  font-size: 16px;
  color: #fff;
  font-family:  var(--interrSemiBold-fonts);
  justify-content: center;
  margin-bottom: 0px;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.det-touch-suceess {
  width: 100%;
  float: left;
 font-family:  var(--interBold-fonts);
  color: #4caf50;
  font-size: 20px;
}
.det-touch-suceess a {
  color: var(--primaryColor);
}
p.ltai_label {
  color: var(--labelField);
  font-size: 12px;
  padding-top: 10px;
}
.transit-text-app.margin-l a {
  color: var(--primaryColor);
 font-family:  var(--interBold-fonts);
}
.transit-text-app.margin-l {
  margin-top: 60px;
  width: 100%;
  float: left;
}
.get-box p.li_error_text {
  margin: 0px !important;
}
.get-box p {
  margin: 0px !important;
}
.input-b svg {
  margin: 0px !important;
  width: inherit;
}
.App svg {
  margin: 0px !important;
}
/* header.trasit-header .collapse:not(.show) {
  display: block !important;
} */

.gcterm-loader {
  width: 100%;
  float: left;
}
p.li_error_text {
  font-size: 12px;
  color: red;
  text-align: left;
}
.partner-call-section {
  width: 100%;
  float: left;
  background: #f7f7f7;
  padding: 20px 0px;
}
div#why {
  width: 100%;
  float: left;
}
.main-load {
  height: 90vh;
  display: flex;
  justify-content: center;
}
.css-py9e6c-control {
  border-color: var(--primaryColor) !important;
}
.css-111d3h-control:hover {
  border-color: var(--primaryColor) !important;
}
.css-111d3h-control {
  box-shadow: unset !important;
  border-color: var(--primaryColor) !important;
}
.label-box-name {
  margin-top: 16px;
  width: 100%;
  float: left;
  margin-bottom: 0px;
  color: var(--labelField);
  font-size: 24px;
  font-family:  var(--interrRegular-fonts);
  line-height: 36px;
  font-weight: unset !important;
  padding: 10px 10px 10px 0px;
}
.label-box-name span {
  color: red;
}
.term-txt a {
  color: #0091ff;
  text-decoration: underline;
}
ul.navbar-nav li a.nav-link.bg-color:hover {
  color: #fff;
}
nav.navbar.navbar-expand-lg.navbar-light {
  padding: 10px 12px !important;
}
.accept-t-c .term-head-boxx {
  margin-top: 0px;
}
.term-submit-buttons.term-btn-ccc {
  justify-content: flex-end;
  width: 100%;
  float: left;
  display: flex;
  margin-bottom: 80px;
}
.btn-scor {
  width: 100%;
  float: left;
}
.new-term-updated {
  width: 100%;
  float: left;
}
.new-term-updated label.checkbox-label {
  color: var(--labelField) !important;
}
.new-term-updated label.checkbox-label a {
  color: #3481F2;
  text-decoration: underline;
}
.new-term-updated .check-wrap input:checked ~ .checkmark1 {
  background: #3481F2;
}
.react-tel-input input {
  box-sizing: border-box;
  border: 1px solid var(--primaryColor) !important;
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
  outline: none;
  height: 48px;
  color: #646464;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  font-family:  var(--interrRegular-fonts);
}
.by_click {
  font-size: 20px;
  color: #545454;
  line-height: 30px;
  font-family:  var(--interrRegular-fonts) !important;
  margin-top: 30px;
  width: 100%;
  float: left;
  text-align: left;
  padding-top: 24px;
  padding-bottom: 16px;
}
.heading_Style {
  font-size: 20px;
  color: #545454;
  line-height: 30px;
  font-family:  var(--interrRegular-fonts) !important;
  margin-top: 30px;
  width: 100%;
  float: left;
  text-align: center;
  padding-top: 24px;
  margin-bottom: -30px;
}
.li_input_container input.phoneNumberInput {
    width: 100% !important;
    border: 1px solid var(--primaryColor) !important;
    display: inline-block;
    font-size: 20px;
    line-height: 30px;
    outline: none;
    height: 48px;
    color: #646464;
    border-radius: 4px;
    /* padding: 10px; */
    font-family:  var(--interrRegular-fonts);
}
.react-tel-input .flag-dropdown {
    border: 1px solid var(--primaryColor) !important;
}

/********************************** FAQs Page ***********************************/
.faqs_mean_box {
  width: 100%;
  float: left;
}
.faqs_mean_box button.accordion-button {
  font-size: 20px;
  color: #000;
  font-family:  var(--interrSemiBold-fonts);
  text-decoration: none !important;
  width: 100%;
  text-align: left;
  padding: 20px;
}
.faqs_mean_box .accordion-item {
  background-color: #fff !important;
  border: 0px;
}
.faqs_mean_box .accordion-button:not(.collapsed) {
  background: transparent;
  box-shadow: none !important;
}
.faqs_mean_box h2.accordion-header {
  border: 0px !important;
}
.faqs_mean_box .accordion-button::after{
  display: none !important;
}
.faqs_mean_box .accordion-body {
  color: #7c7c7c;
  font-size: 20px;
  line-height: 30px;
  font-family: var(--interrRegular-fonts);
}

.faqs_mean_box button.nav-link {
  border: 3px solid #fff !important;
  box-shadow: 1px 2px 5px #000;
  text-decoration: none !important;
  width: 100%;
  border-radius: 8px;
}
.faqs_mean_box button.nav-link.active {
  border: 3px solid var(--primaryColor)!important
}
.faqs_mean_box  .nav-tabs {
  border-bottom: 1px solid #dee2e6 !important;
  padding-bottom: 20px;
}
.faqs_mean_box .help-form-btn {
  padding-top: 20px;
}



/********************************** Mobile Responsive ***********************************/
@media only screen and (max-width: 767px) {
  .faqs_mean_box button.nav-link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}
.h-img img {
  height: 30px;
}

  .btn-scor .term-submit-buttons.term-btn-ccc {
    justify-content: center;
    margin-bottom: 40px;
  }
  .clddi_input_container .clddi_div1 .clddi_container .clddi_input {
    padding: 14px 10px !important;
  }
  .mobile-img {
    display: block;
  }
  .label-box-name {
    font-size: 16px;
    line-height: 24px;
  }
  .resp-desk {
    display: none;
  }
  .mob-res {
    width: 96%;
  }
  .two-btn {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .crousel-txt-box {
    font-size: 18px;
    line-height: 30px;
  }
}
/********************************** Mobile Responsive ***********************************/
@media only screen and (min-width: 768px) {
  .heading-box-b.deskstop{
    display: none;
  }
  .heading-box-b.mobile{
    display: block;
  }
  .by_click{
    font-size: 16px;
    line-height: 24px;
  }
  .mobile-img {
    display: none;
  }
  .resp-desk {
    display: block;
  }
}
@media only screen and (max-width: 460px) {
  .input-b.user-name.margin {
    margin-top: 26px !important;
  }
}
/********************************** Mobile Responsive ***********************************/
@media only screen and (max-width: 600px) {
  header.site-navbar.js-sticky-header.site-navbar-target.shrink {
    width: 100%;
    float: left;
  }

  .trasit-partner {
    width: 100%;
    margin-bottom: 30px;
  }
  .partner-head.transit {
    display: unset !important;
  }
  .shedule-call-btn {
    width: 100% !important;
    text-align: center;
  }
  .heading-box.expert {
    padding-bottom: 40px;
  }
  /* .heading-box.expert {
    min-height: 300px;
}
.heading-box.expert {
  min-height: 300px;
} */
  .partner-button1 {
    width: 100%;
    margin-top: 30px;
  }
  .two-btn {
    display: unset;
  }
  .partner-button1.margin {
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .t-copy {
    text-align: center;
  }
  .tsocail-link {
    align-items: center;
    margin-top: 20px;
  }
  ul.t-links-footer {
    padding-left: 0px;
    justify-content: center;
  }
  .slider-wrapper.axis-horizontal {
    margin-bottom: 60px;
  }
  .site-mobile-menu.site-navbar-target {
    margin-top: 55px;
  }
  a.burger.site-menu-toggle.js-menu-toggle.active {
    visibility: hidden;
  }
  ul.carousel-indicators {
    margin-left: auto !important;
    margin-bottom: -20px;
  }
}
.google-recaptcha {
  display: flex;
  align-items: left;
  justify-content: flex-start;
}

.google-recaptcha-msg {
  /* margin-top: 40px; */
}

.google-recaptcha-msg p {
  text-align: left;
  color: red;
  font-family:  var(--interrRegular-fonts);
  font-size: 20px;
}
a.mb-0.img-box img {
  width: 90px;
  height: 90px;
}
a.mb-0.img-box {
  padding: 10px 0px;
  float: left;
}
h1.mb-0.transit-logo a.mb-0 p {
  width: 100%;
  float: left;
  text-align: left;
}

.zoho-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

/********************************** Tabs Responsive ***********************************/

@media only screen and (max-width: 1024px) and (min-width: 600px) {
  .shedule-call-btn a {
    padding: 13px 4px;
    font-size: 14px;
  }
  .partner-button1 a {
    font-size: 14px;
  }
  .trasit-partner {
    width: 100%;
    margin-bottom: 30px;
  }
  .partner-head.transit {
    display: unset;
    /* min-height: 220px; */
  }
  /* .heading-box.expert {
  min-height: 220px;
} */
  .heading-box.expert {
    padding-bottom: 40px;
  }
  .shedule-call-btn {
    width: 100%;
    text-align: center;
  }
  header.site-navbar.js-sticky-header.site-navbar-target.shrink {
    width: 100%;
    float: left;
  }
  .site-mobile-menu.site-navbar-target {
    margin-top: 55px;
  }
  a.burger.site-menu-toggle.js-menu-toggle.active {
    visibility: hidden;
  }
}

.conatct-loading-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.njt-not-txt a {
  color: #0091ff;
  cursor: pointer;
}

.njt-not-txt a:hover {
  text-decoration: underline;
  color: #0091ff;
}
